import styled from "styled-components";

export const FilmContainer = styled.div`
    .slick-list {
        padding: 0 0 0 20% !important;

        @media only screen and (max-width: 992px) {
            padding: 0 0 0 0% !important;
        }
    }

    .slick-slider {
        width: 100%;
        margin: 0 auto;
        position: relative;

        @media only screen and (min-width: 768px) {
            &::after {
                content: "";
                position: absolute;
                width: 200px;
                height: 100%;
                background-image: linear-gradient(to left, #ffffff, #ffffff00);
                top: 0;
                right: 0;
            }
        }
    }

    .slick-slider .slick-slide {
        &:focus-visible {
            outline: unset !important;
        }
    }

    .slick-slide .image-slide {
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 0 7.5px;
        overflow: hidden;
        border-radius: 16px;

        > div {
            width: 100%;
        }
    }
    .slick-slide video {
        display: block;
        width: calc(100% - 30px);
        margin: auto;
        overflow: hidden;
        border-radius: 16px;
    }
`;
