// import Marquee from "react-fast-marquee";
import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform, useMotionValue, useVelocity, useAnimationFrame } from "framer-motion";
import { Images } from "../../config/images";
import { Image } from "react-bootstrap";
// import { wrap } from "@motionone/utils";

const wrap = (min, max, v) => {
    const rangeSize = max - min;
    return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

function ParallaxText({ children, baseVelocity = 100, compStyle }) {
    const baseY = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });

    const y = useTransform(baseY, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseY.set(baseY.get() + moveBy);
    });

    return (
        <div className="parallax linerBg" style={compStyle}>
            <motion.div className="scroller" style={{ y }}>
                {children}
            </motion.div>
        </div>
    );
}

const MFBackground = (props) => {
    return (
        <ParallaxText baseVelocity={-0.9} compStyle={props.style}>
            <Image src={Images.darkLinerBgImg} />
            <Image src={Images.darkLinerBgImg} />
            <Image src={Images.darkLinerBgImg} />
            <Image src={Images.darkLinerBgImg} />
        </ParallaxText>
    );
};

export default MFBackground;
