import styled from "styled-components";
import GradientButton from "../../../components/GradientButton";

export const ButtonContainer = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 480px) {
        padding: 0 20px;
    }
`;

export const ProjectTitleContainer = styled.div`
    padding: 1em 0 60px;
    display: flex;
    align-items: center;

    img {
        width: 50px;
        height: auto;
    }

    p {
        margin: 0;
        padding: 0;
        font-family: "Helvetica Neue LT Std";
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        margin-left: 25px;
    }

    @media (max-width: 767px) {
        padding: 0px 0;
        margin-bottom: 15px;
    }
    @media (max-width: 480px) {
        p {
            font-size: 24px;
        }

        img,
        svg {
            width: 52px;
            height: 52px;
        }
    }
`;

export const FeaturesContainer = styled.div`
    h3 {
        font-family: "Helvetica Neue LT Std";
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
    }

    ul {
        li {
            font-family: "Helvetica";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;

            @media only screen and (min-width: 1600px) {
                font-size: 20px;
            }
        }
    }

    .flex {
        max-width: 650px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 480px) {
        p {
            font-size: 28px;
        }

        ul {
            li {
                font-size: 16px;
            }
        }
    }
`;

export const AboutThisProjectContainer = styled.div`
    width: 100%;
    margin-top: 60px;

    h3 {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
    }

    p {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;

        @media only screen and (min-width: 1600px) {
            font-size: 20px;
        }
    }

    @media (max-width: 480px) {
        padding: 0 0px;
        margin-top: 30px;

        p:nth-child(1) {
            font-size: 28px;
        }

        p:nth-child(2) {
            font-size: 16px;
        }
    }
`;

export const ContactButtonsContainer = styled.div`
    width: 100%;
    padding: 0 0em;
    margin-top: 60px;
    display: flex;
    align-items: center;

    a {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 300;
        color: #ffffff;
        margin-left: 20px;

        font-size: 20px;

        @media only screen and (min-width: 1600px) {
            font-size: 18px;
        }
    }

    @media (max-width: 480px) {
        padding: 0 0px;
        margin-top: 0px;
        flex-wrap: wrap;
        gap: 15px;
        align-items: center;

        a {
            display: inline-block;
            font-size: 16px;
            margin-left: 0px;
        }
    }
`;

export const VisitWebsiteButton = styled(GradientButton)`
    /* filter: drop-shadow(0px 21px 35px rgba(0, 0, 0, 0.13)); */
`;

export const IconButton = styled.button`
    background: #202021;
    border: 1px solid #585859;
    color: #ffffff;
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    border-radius: 16px;
    filter: drop-shadow(0px 21px 35px rgba(0, 0, 0, 0.13));
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
        path {
            stroke: #ffffff;
        }
    }
`;
