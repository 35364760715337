import { Button } from "react-bootstrap";
import { Images } from "../../config/images";
import styled from "styled-components";

export const GradientBtn = styled(Button)`
    background-color: transparent;
    background-image: url("${Images.ddBgImg}");
    background-size: 100% 100%;
    background-position: center center;
    border-radius: 0px !important;
    font-size: 16px;
    width: 200px;
    height: 50px;
    /* width: 246px;
    height: 69px; */

    padding: 2px;
    border: none;
    border-radius: 0px !important;
    box-sizing: border-box;

    @media only screen and (min-width: 1440px) {
        font-size: 16px;
    }

    span {
        color: #ffffff;
        padding: 10px 15px;
        border-radius: 0px !important;
        box-sizing: border-box;
        transition: background 0.3s ease-in-out;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        width: 100%;
    }

    &.centerText {
        span {
            justify-content: center;
        }
    }

    &:hover {
        background-color: transparent !important;
        background-image: url("${Images.ddBgReverseImg}");
    }
`;
export const GradientDotBtn = styled(Button)`
    position: relative;
    width: 200px;
    height: 55px;
    background: rgba(21, 21, 24, 0.4);

    backdrop-filter: blur(100px);
    border-radius: 16px !important;
    border: 0px solid transparent !important;
    overflow: hidden;
    padding: 0px !important;

    @media only screen and (max-width: 600px) {
        width: 135px;
        height: 45px;
    }

    &:hover {
        background: rgba(21, 21, 24, 0.4);
        border: 0px solid transparent !important;
        .btntext {
            border: 5px solid transparent !important;
            color: #ffffff !important;
        }
    }

    .btntext {
        position: relative;
        width: calc(100% - 2px);
        height: calc(100% - 2.5px);
        left: 0;
        top: 0;
        z-index: 6;
        background: rgba(21, 21, 24, 0.8);
        backdrop-filter: blur(100px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px solid transparent !important;
        border-radius: 14px 14px 16px 14px !important;
        margin: auto !important;
        color: #ffffff !important;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;

        @media only screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    .ellip7 {
        width: 200%;
        height: 250%;
        border-radius: 0px !important;
        position: absolute;
        z-index: 5;
        top: ${(props) => props.posy + "px"};
        left: ${(props) => props.posx + "px"};
        display: block;
        background-image: radial-gradient(rgba(170, 170, 170, 1), transparent);
        transform: translate(-50%, -60%);
    }

    .rect40 {
        box-sizing: border-box;

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        /* background: rgb(255 0 157); */
        background: rgba(21, 21, 24, 1);
        backdrop-filter: blur(100px);
        border-radius: 0px !important;
        z-index: 4;
    }
`;
export const GradientDotBox = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    background: rgba(21, 21, 24, 0.4);
    backdrop-filter: blur(100px);
    border-radius: 16px !important;
    border: 0px solid transparent !important;
    overflow: hidden;
    padding: 0px !important;

    &:hover {
        background: rgba(21, 21, 24, 0.4);
        border: 0px solid transparent !important;
        .btntext {
            border: 5px solid transparent !important;
            color: #ffffff;
        }
    }

    .btntext {
        position: relative;
        width: calc(100% - 2px);
        height: calc(100% - 2.5px);
        left: 0;
        top: 0;
        z-index: 6;
        background: rgba(21, 21, 24, 0.8);
        backdrop-filter: blur(100px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 5px solid transparent !important;
        border-radius: 14px 14px 16px 14px !important;
        margin: auto !important;
    }
    .rect40 {
        box-sizing: border-box;

        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        /* background: rgb(255 0 157); */
        background: rgba(21, 21, 24, 0.3);
        backdrop-filter: blur(100px);
        border-radius: 0px !important;
        z-index: 5;
    }

    .ellip7 {
        width: 100%;
        height: 100%;
        border-radius: 0px !important;
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        display: block;
        background-color: rgba(170, 170, 170, 1);
        backdrop-filter: blur(100px);
        clip-path: circle(120px at ${(props) => props.posx + "px"} ${(props) => props.posy + "px"});
    }
`;
