import { Images, PhotographyImages } from "../config/images";

export const CategoryList = [
    {
        id: 1,
        name: "Beauty & beach",
        thumbnail: Images.beautyAndBeachThumb,
        images: [
            PhotographyImages.beautyAndBeach1,
            PhotographyImages.beautyAndBeach2,
            PhotographyImages.beautyAndBeach3,
            PhotographyImages.beautyAndBeach4,
            PhotographyImages.beautyAndBeach5,
            PhotographyImages.beautyAndBeach6,
            PhotographyImages.beautyAndBeach7,
            PhotographyImages.beautyAndBeach8,
            PhotographyImages.beautyAndBeach9,
            PhotographyImages.beautyAndBeach10
        ]
    },
   /* {
        id: 2,
        name: "People of Chennai",
        thumbnail: Images.peopleOfChennaiThumb,
        images: [
            PhotographyImages.peopleOfChennai1,
            PhotographyImages.peopleOfChennai2,
            PhotographyImages.peopleOfChennai3,
            PhotographyImages.peopleOfChennai4,
            PhotographyImages.peopleOfChennai5,
            PhotographyImages.peopleOfChennai6,
            PhotographyImages.peopleOfChennai7,
            PhotographyImages.peopleOfChennai8,
            PhotographyImages.peopleOfChennai9,
            PhotographyImages.peopleOfChennai10,
            PhotographyImages.peopleOfChennai11,
            PhotographyImages.peopleOfChennai12,
            PhotographyImages.peopleOfChennai13
        ]
    }, */
    {
        id: 3,
        name: "Jane in Mahabas: part 2",
        thumbnail: Images.janeInMahabstwo,
        images: [
            PhotographyImages.janeInMahabstwo1,
            PhotographyImages.janeInMahabstwo2,
            PhotographyImages.janeInMahabstwo3,
            PhotographyImages.janeInMahabstwo4,
            PhotographyImages.janeInMahabstwo5,
            PhotographyImages.janeInMahabstwo6,
            PhotographyImages.janeInMahabstwo7,
            PhotographyImages.janeInMahabstwo8,
            PhotographyImages.janeInMahabstwo9,
            PhotographyImages.janeInMahabstwo10
        ]
    },
    {
        id: 5,
        name: "Jane in Mahabas",
        thumbnail: Images.janeInMahabsThumb,
        images: [
            PhotographyImages.janeInMahabs1,
            PhotographyImages.janeInMahabs2,
            PhotographyImages.janeInMahabs3,
            PhotographyImages.janeInMahabs4,
            PhotographyImages.janeInMahabs5,
            PhotographyImages.janeInMahabs6,
            PhotographyImages.janeInMahabs7,
            PhotographyImages.janeInMahabs8,
            PhotographyImages.janeInMahabs9,
            PhotographyImages.janeInMahabs10
        ]
    },
    {
        id: 6,
        name: "Nier: Automata 2B's play",
        thumbnail: Images.nierAutomataThumb,
        images: [
            PhotographyImages.nierAutomata1,
            PhotographyImages.nierAutomata2,
            PhotographyImages.nierAutomata3,
            PhotographyImages.nierAutomata4,
            PhotographyImages.nierAutomata5,
            PhotographyImages.nierAutomata6,
            PhotographyImages.nierAutomata7,
            PhotographyImages.nierAutomata8,
            PhotographyImages.nierAutomata9,
            PhotographyImages.nierAutomata10,
            PhotographyImages.nierAutomata11,
            PhotographyImages.nierAutomata12,
            PhotographyImages.nierAutomata13
        ]
    },
    {
        id: 7,
        name: "Lost in beauty forest",
        thumbnail: Images.lostInBeautyForestThumb,
        images: [
            PhotographyImages.lostInBeautyForest1,
            PhotographyImages.lostInBeautyForest2,
            PhotographyImages.lostInBeautyForest3,
            PhotographyImages.lostInBeautyForest4,
            PhotographyImages.lostInBeautyForest5,
            PhotographyImages.lostInBeautyForest6,
            PhotographyImages.lostInBeautyForest7,
            PhotographyImages.lostInBeautyForest8,
            PhotographyImages.lostInBeautyForest9,
            PhotographyImages.lostInBeautyForest10,
            PhotographyImages.lostInBeautyForest11,
            PhotographyImages.lostInBeautyForest12,
            PhotographyImages.lostInBeautyForest13,
            PhotographyImages.lostInBeautyForest14
        ]
    },
    {
        id: 8,
        name: "The real deal",
        thumbnail: Images.theRealDealThumb,
        images: [
            PhotographyImages.theRealDeal1,
            PhotographyImages.theRealDeal2,
            PhotographyImages.theRealDeal3,
            PhotographyImages.theRealDeal4,
            PhotographyImages.theRealDeal5,
            PhotographyImages.theRealDeal6,
            PhotographyImages.theRealDeal7,
            PhotographyImages.theRealDeal8,
            PhotographyImages.theRealDeal9,
            PhotographyImages.theRealDeal10,
            PhotographyImages.theRealDeal11,
            PhotographyImages.theRealDeal12,
            PhotographyImages.theRealDeal13,
            PhotographyImages.theRealDeal14,
            PhotographyImages.theRealDeal15
        ]
    },
    {
        id: 9,
        name: "Picnic in the Jungle",
        thumbnail: Images.picnicInTheJungleThumb,
        images: [
            PhotographyImages.picnicInTheJungle1,
            PhotographyImages.picnicInTheJungle2,
            PhotographyImages.picnicInTheJungle3,
            PhotographyImages.picnicInTheJungle4,
            PhotographyImages.picnicInTheJungle5,
            PhotographyImages.picnicInTheJungle6,
            PhotographyImages.picnicInTheJungle7,
            PhotographyImages.picnicInTheJungle8,
            PhotographyImages.picnicInTheJungle9,
            PhotographyImages.picnicInTheJungle10,
            PhotographyImages.picnicInTheJungle11,
            PhotographyImages.picnicInTheJungle12,
            PhotographyImages.picnicInTheJungle13,
            PhotographyImages.picnicInTheJungle14,
            PhotographyImages.picnicInTheJungle15
        ]
    },
    {
        id: 10,
        name: "Mermaids bathe here",
        thumbnail: Images.mermaidsBatheHereThumb,
        images: [
            PhotographyImages.mermaidsBatheHere1,
            PhotographyImages.mermaidsBatheHere2,
            PhotographyImages.mermaidsBatheHere3,
            PhotographyImages.mermaidsBatheHere4,
            PhotographyImages.mermaidsBatheHere5,
            PhotographyImages.mermaidsBatheHere6,
            PhotographyImages.mermaidsBatheHere7,
            PhotographyImages.mermaidsBatheHere8,
            PhotographyImages.mermaidsBatheHere9,
            PhotographyImages.mermaidsBatheHere10,
            PhotographyImages.mermaidsBatheHere11
        ]
    },
    {
        id: 11,
        name: "Dancing in the rainforest",
        thumbnail: Images.dancingInTheRainforestThumb,
        images: [
            PhotographyImages.dancingInTheRainforest1,
            PhotographyImages.dancingInTheRainforest2,
            PhotographyImages.dancingInTheRainforest3,
            PhotographyImages.dancingInTheRainforest4,
            PhotographyImages.dancingInTheRainforest5,
            PhotographyImages.dancingInTheRainforest6,
            PhotographyImages.dancingInTheRainforest7,
            PhotographyImages.dancingInTheRainforest8,
            PhotographyImages.dancingInTheRainforest9,
            PhotographyImages.dancingInTheRainforest10,
            PhotographyImages.dancingInTheRainforest11,
            PhotographyImages.dancingInTheRainforest12
        ]
    },
    {
        id: 12,
        name: "Odhna: The Traditional Red",
        thumbnail: Images.odhanaTheTraditionalRedThumb,
        images: [
            PhotographyImages.odhanaTheTraditionalRed1,
            PhotographyImages.odhanaTheTraditionalRed2,
            PhotographyImages.odhanaTheTraditionalRed3,
            PhotographyImages.odhanaTheTraditionalRed4,
            PhotographyImages.odhanaTheTraditionalRed5,
            PhotographyImages.odhanaTheTraditionalRed6,
            PhotographyImages.odhanaTheTraditionalRed7,
            PhotographyImages.odhanaTheTraditionalRed8,
            PhotographyImages.odhanaTheTraditionalRed9,
            PhotographyImages.odhanaTheTraditionalRed10,
            PhotographyImages.odhanaTheTraditionalRed11,
            PhotographyImages.odhanaTheTraditionalRed12,
            PhotographyImages.odhanaTheTraditionalRed13,
            PhotographyImages.odhanaTheTraditionalRed14,
            PhotographyImages.odhanaTheTraditionalRed15,
            PhotographyImages.odhanaTheTraditionalRed16,
            PhotographyImages.odhanaTheTraditionalRed17,
            PhotographyImages.odhanaTheTraditionalRed18,
            PhotographyImages.odhanaTheTraditionalRed19,
            PhotographyImages.odhanaTheTraditionalRed20
        ]
    },
    {
        id: 13,
        name: "Pretty girl in the house",
        thumbnail: Images.prettyGirlThumb,
        images: [
            PhotographyImages.prettyGirl1,
            PhotographyImages.prettyGirl2,
            PhotographyImages.prettyGirl3,
            PhotographyImages.prettyGirl4,
            PhotographyImages.prettyGirl5,
            PhotographyImages.prettyGirl6,
            PhotographyImages.prettyGirl7,
            PhotographyImages.prettyGirl8,
            PhotographyImages.prettyGirl9,
            PhotographyImages.prettyGirl10,
            PhotographyImages.prettyGirl11,
            PhotographyImages.prettyGirl12,
            PhotographyImages.prettyGirl13,
            PhotographyImages.prettyGirl14,
            PhotographyImages.prettyGirl15,
            PhotographyImages.prettyGirl16
        ]
    },
    {
        id: 14,
        name: "The Future",
        thumbnail: Images.thefuture,
        images: [
            PhotographyImages.thefuture1,
            PhotographyImages.thefuture2,
            PhotographyImages.thefuture3,
            PhotographyImages.thefuture4,
            PhotographyImages.thefuture5,
            PhotographyImages.thefuture6,
            PhotographyImages.thefuture7,
            PhotographyImages.thefuture8,
            PhotographyImages.thefuture9,
            PhotographyImages.thefuture10,
            PhotographyImages.thefuture11,
            PhotographyImages.thefuture12,
            PhotographyImages.thefuture13,
            PhotographyImages.thefuture14
        ]
    },
    {
        id: 15,
        name: "The Anglic bird",
        thumbnail: Images.theangelicbird,
        images: [
            PhotographyImages.theangelicbird1,
            PhotographyImages.theangelicbird2,
            PhotographyImages.theangelicbird3,
            PhotographyImages.theangelicbird4,
            PhotographyImages.theangelicbird5,
            PhotographyImages.theangelicbird6,
            PhotographyImages.theangelicbird7,
            PhotographyImages.theangelicbird8,
            PhotographyImages.theangelicbird9,
            PhotographyImages.theangelicbird10,
            PhotographyImages.theangelicbird11,
            PhotographyImages.theangelicbird12
        ]
    },
];
