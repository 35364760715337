import { ReactComponent as AkashLogo } from "../assets/images/akashLogo.svg";
import { ReactComponent as AkashLogoBlack } from "../assets/images/akashLogoBlack.svg";
import { ReactComponent as PlayIcon } from "../assets/images/playIcon.svg";
import { ReactComponent as NextArrow } from "../assets/images/nextArrow.svg";
import { ReactComponent as LinkedInDarkLight } from "../assets/images/linkedinWhiteLogo.svg";
import { ReactComponent as NextArrowLight } from "../assets/images/nextArrowLight.svg";
import { ReactComponent as HomeIcon } from "../assets/images/homeIcon.svg";

import namansteImg from "../assets/images/namasteHand@2x.png";
import businessCardImg from "../assets/images/businessCard@2x.png";
import indianExpressImg from "../assets/images/indianExpress@2x.png";
import indianExpressDarkImg from "../assets/images/indianExpressDark@2x.png";

import dtNextImg from "../assets/images/dtNext@2x.png";
import dtNextDarkImg from "../assets/images/dtNextDark@2x.png";
import filmBanner from "../assets/images/filmBanner@2x.png";
import sidebarBackground from "../assets/images/sidebarBackground.png";
// liners
import lightLinerImg from "../assets/images/bgLinerImgLight.png";
import darkLinerBgImg from "../assets/images/bglinerImg.png";

import projectsBanner from "../assets/images/projectsBanner.png";
import snaplarkLogo from "../assets/images/snaplarkLogo.png";
import projectImage from "../assets/images/projectImage.png";
import fanolly from "../assets/images/projects/fanolly.png";
import washi from "../assets/images/projects/washi.png";
import sumofame from "../assets/images/projects/sumofame.png";
import closeIcon from "../assets/images/closeCircle@2x.png";

//header
import akashfaceImg from "../assets/images/akashfaceImg.png";

//home
// Project Slider

import washiImg from "../assets/images/washi@2x.png";
import snaplarkImg from "../assets/images/snaplark@2x.png";
import cheesieImg from "../assets/images/cheesie@2x.png";

import washiSlideImg from "../assets/images/projectSlides/washiSlideImg.png";
import snaplarkSlideImg from "../assets/images/projectSlides/snaplarkSlideImg.png";
import cheesieSlideImg from "../assets/images/projectSlides/chessesSlideImg.png";
import sumofameSlideImg from "../assets/images/projectSlides/sumofameSlideImg.png";
import fanollySlideImg from "../assets/images/projectSlides/fanollySlideImg.png";

// home project section bg images
import washibgimg from "../assets/images/washibgimg.jpg";
import cheeisebgimg from "../assets/images/cheeisebgimg.png";

//home projects
import snaplarkBgImg from "../assets/images/snaplarkBg@2x.png";
import shapeL1Img from "../assets/images/homeProjects/L1.svg";
import shapeL2Img from "../assets/images/homeProjects/L2.svg";

import fireImg from "../assets/images/homeProjects/sumo/fire-icon@1.5x.png";
import sumofameImg from "../assets/images/homeProjects/sumo/sumofame-logo@1.5x.png";
import sumoBg1 from "../assets/images/homeProjects/sumo/how-bg@1.5x.png";
import sumoBg2 from "../assets/images/homeProjects/sumo/red-bg@1.5x.png";
import sumoIcon from "../assets/images/homeProjects/sumo/sumo-icon-1.png";
import arrowImg from "../assets/images/homeProjects/sumo/arrow.png";
import cloud1 from "../assets/images/homeProjects/sumo/1.png";
import cloud2 from "../assets/images/homeProjects/sumo/2.png";
import cloud3 from "../assets/images/homeProjects/sumo/3.png";
import cloud4 from "../assets/images/homeProjects/sumo/4.png";
import cloud5 from "../assets/images/homeProjects/sumo/5.png";

// SM Icons
import { ReactComponent as InstagramLightLogo } from "../assets/images/newIcons/instagram.svg";
import { ReactComponent as LinkedinLightLogo } from "../assets/images/newIcons/linkedinWhiteN.svg";
import { ReactComponent as InstagramDarkLogo } from "../assets/images/newIcons/instagramDark.svg";
import { ReactComponent as LinkedInDarkLogo } from "../assets/images/newIcons/linkedinDarkN.svg";

// icons
import { ReactComponent as UpArrow } from "../assets/images/newIcons/arrowLeft.svg";
import { ReactComponent as SendIcon } from "../assets/images/newIcons/send.svg";
import { ReactComponent as CloseCircleIcon } from "../assets/images/newIcons/closeCircle.svg";
import { ReactComponent as ProjectLinkIcon } from "../assets/images/newIcons/projectLink.svg";
import { ReactComponent as ProjectLinkHoverIcon } from "../assets/images/newIcons/projectLinkHover.svg";
import { ReactComponent as VolumeUnMuteIcon } from "../assets/images/newIcons/volumeUnMute.svg";
import { ReactComponent as VolumeMuteIcon } from "../assets/images/newIcons/volumeMute.svg";
import { ReactComponent as PlayCircleFill } from "../assets/images/newIcons/playCircleFill.svg";
import { ReactComponent as PauseCircleFill } from "../assets/images/newIcons/pauseCircleFill.svg";
import { ReactComponent as RightArrow } from "../assets/images/newIcons/arrowRight.svg";
import { ReactComponent as FullscreenIcon } from "../assets/images/newIcons/fullscreen.svg";
import { ReactComponent as FullscreenExitIcon } from "../assets/images/newIcons/fullscreenExit.svg";

// DD menu bg
import ddMenuBgImg from "../assets/images/newIcons/ddMenuBgImg.png";
import ddBgImg from "../assets/images/newIcons/ddBgImg.png";
import ddBgReverseImg from "../assets/images/newIcons/ddBgImgReverse.png";

//media
import snaplarkVideo from "../assets/media/snaplark_video.mp4";

//photography Images
import beautyAndBeachThumb from "../assets/images/photography/beauty_and_the_beach/featuredImage.png";
import janeInMahabsThumb from "../assets/images/photography/jane_in_mahabs/featuredImage.png";
import janeInMahabstwo from "../assets/images/photography/jane_in_mahabs_2/featuredImage.png";
import lostInBeautyForestThumb from "../assets/images/photography/lost_in_beauty_forest/featuredImage.png";
import nierAutomataThumb from "../assets/images/photography/nier_automata/featuredImage.png";
import dancingInTheRainforestThumb from "../assets/images/photography/dancing_in_the_rainforest/featuredImage.png";
import mermaidsBatheHereThumb from "../assets/images/photography/mermaids_bathe_here/featuredImage.png";
import odhanaTheTraditionalRedThumb from "../assets/images/photography/odhna/featuredImage.png";
import picnicInTheJungleThumb from "../assets/images/photography/picnic_in_the_jungle/featuredImage.png";
import prettyGirlThumb from "../assets/images/photography/pretty_girl/featuredImage.png";
import theRealDealThumb from "../assets/images/photography/the_real_deal/featuredImage.png";
import thefuture from "../assets/images/photography/the_future/featuredImage.jpg";
import theangelicbird from "../assets/images/photography/the_caged_bird/featuredImage.jpg";

//photography Inner Images

//thefuture

import thefuture1 from "../assets/images/photography/the_future/1.jpg";
import thefuture2 from "../assets/images/photography/the_future/2.jpg";
import thefuture3 from "../assets/images/photography/the_future/3.jpg";
import thefuture4 from "../assets/images/photography/the_future/4.jpg";
import thefuture5 from "../assets/images/photography/the_future/5.jpg";
import thefuture6 from "../assets/images/photography/the_future/6.jpg";
import thefuture7 from "../assets/images/photography/the_future/7.jpg";
import thefuture8 from "../assets/images/photography/the_future/8.jpg";
import thefuture9 from "../assets/images/photography/the_future/9.jpg";
import thefuture10 from "../assets/images/photography/the_future/10.jpg";
import thefuture11 from "../assets/images/photography/the_future/11.jpg";
import thefuture12 from "../assets/images/photography/the_future/12.jpg";
import thefuture13 from "../assets/images/photography/the_future/13.jpg";
import thefuture14 from "../assets/images/photography/the_future/14.jpg";
import thefuture15 from "../assets/images/photography/the_future/15.jpg";

//theangelicbird

import theangelicbird1 from "../assets/images/photography/the_caged_bird/1.jpg";
import theangelicbird2 from "../assets/images/photography/the_caged_bird/2.jpg";
import theangelicbird3 from "../assets/images/photography/the_caged_bird/3.jpg";
import theangelicbird4 from "../assets/images/photography/the_caged_bird/4.jpg";
import theangelicbird5 from "../assets/images/photography/the_caged_bird/5.jpg";
import theangelicbird6 from "../assets/images/photography/the_caged_bird/6.jpg";
import theangelicbird7 from "../assets/images/photography/the_caged_bird/7.jpg";
import theangelicbird8 from "../assets/images/photography/the_caged_bird/8.jpg";
import theangelicbird9 from "../assets/images/photography/the_caged_bird/9.jpg";
import theangelicbird10 from "../assets/images/photography/the_caged_bird/10.jpg";
import theangelicbird11 from "../assets/images/photography/the_caged_bird/11.jpg";
import theangelicbird12 from "../assets/images/photography/the_caged_bird/12.jpg";


//therealdeal

import theRealDeal1 from "../assets/images/photography/the_real_deal/1.jpg";
import theRealDeal2 from "../assets/images/photography/the_real_deal/2.jpg";
import theRealDeal3 from "../assets/images/photography/the_real_deal/3.jpg";
import theRealDeal4 from "../assets/images/photography/the_real_deal/4.jpg";
import theRealDeal5 from "../assets/images/photography/the_real_deal/5.jpg";
import theRealDeal6 from "../assets/images/photography/the_real_deal/6.jpg";
import theRealDeal7 from "../assets/images/photography/the_real_deal/7.jpg";
import theRealDeal8 from "../assets/images/photography/the_real_deal/8.jpg";
import theRealDeal9 from "../assets/images/photography/the_real_deal/9.jpg";
import theRealDeal10 from "../assets/images/photography/the_real_deal/10.jpg";
import theRealDeal11 from "../assets/images/photography/the_real_deal/11.jpg";
import theRealDeal12 from "../assets/images/photography/the_real_deal/12.jpg";
import theRealDeal13 from "../assets/images/photography/the_real_deal/13.jpg";
import theRealDeal14 from "../assets/images/photography/the_real_deal/14.jpg";
import theRealDeal15 from "../assets/images/photography/the_real_deal/15.jpg";

//prettyGirl
import prettyGirl1 from "../assets/images/photography/pretty_girl/1.jpeg";
import prettyGirl2 from "../assets/images/photography/pretty_girl/2.jpg";
import prettyGirl3 from "../assets/images/photography/pretty_girl/3.jpg";
import prettyGirl4 from "../assets/images/photography/pretty_girl/4.jpg";
import prettyGirl5 from "../assets/images/photography/pretty_girl/5.jpg";
import prettyGirl6 from "../assets/images/photography/pretty_girl/6.jpg";
import prettyGirl7 from "../assets/images/photography/pretty_girl/7.jpg";
import prettyGirl8 from "../assets/images/photography/pretty_girl/8.jpg";
import prettyGirl9 from "../assets/images/photography/pretty_girl/9.jpg";
import prettyGirl10 from "../assets/images/photography/pretty_girl/10.jpg";
import prettyGirl11 from "../assets/images/photography/pretty_girl/11.jpg";
import prettyGirl12 from "../assets/images/photography/pretty_girl/12.jpg";
import prettyGirl13 from "../assets/images/photography/pretty_girl/13.jpg";
import prettyGirl14 from "../assets/images/photography/pretty_girl/14.jpg";
import prettyGirl15 from "../assets/images/photography/pretty_girl/15.jpg";
import prettyGirl16 from "../assets/images/photography/pretty_girl/16.jpg";

import picnicInTheJungle1 from "../assets/images/photography/picnic_in_the_jungle/1.jpg";
import picnicInTheJungle2 from "../assets/images/photography/picnic_in_the_jungle/2.jpg";
import picnicInTheJungle3 from "../assets/images/photography/picnic_in_the_jungle/3.jpg";
import picnicInTheJungle4 from "../assets/images/photography/picnic_in_the_jungle/4.jpg";
import picnicInTheJungle5 from "../assets/images/photography/picnic_in_the_jungle/5.jpg";
import picnicInTheJungle6 from "../assets/images/photography/picnic_in_the_jungle/6.jpg";
import picnicInTheJungle7 from "../assets/images/photography/picnic_in_the_jungle/7.jpg";
import picnicInTheJungle8 from "../assets/images/photography/picnic_in_the_jungle/8.jpg";
import picnicInTheJungle9 from "../assets/images/photography/picnic_in_the_jungle/9.jpg";
import picnicInTheJungle10 from "../assets/images/photography/picnic_in_the_jungle/10.jpg";
import picnicInTheJungle11 from "../assets/images/photography/picnic_in_the_jungle/11.jpg";
import picnicInTheJungle12 from "../assets/images/photography/picnic_in_the_jungle/12.jpg";
import picnicInTheJungle13 from "../assets/images/photography/picnic_in_the_jungle/13.jpg";
import picnicInTheJungle14 from "../assets/images/photography/picnic_in_the_jungle/14.jpg";
import picnicInTheJungle15 from "../assets/images/photography/picnic_in_the_jungle/15.jpg";

// odhanaTheTraditionalRed
import odhanaTheTraditionalRed1 from "../assets/images/photography/odhna/1.jpg";
import odhanaTheTraditionalRed2 from "../assets/images/photography/odhna/2.jpg";
import odhanaTheTraditionalRed3 from "../assets/images/photography/odhna/3.jpg";
import odhanaTheTraditionalRed4 from "../assets/images/photography/odhna/4.jpg";
import odhanaTheTraditionalRed5 from "../assets/images/photography/odhna/5.jpg";
import odhanaTheTraditionalRed6 from "../assets/images/photography/odhna/6.jpg";
import odhanaTheTraditionalRed7 from "../assets/images/photography/odhna/7.jpg";
import odhanaTheTraditionalRed8 from "../assets/images/photography/odhna/8.jpg";
import odhanaTheTraditionalRed9 from "../assets/images/photography/odhna/9.jpg";
import odhanaTheTraditionalRed10 from "../assets/images/photography/odhna/10.jpg";
import odhanaTheTraditionalRed11 from "../assets/images/photography/odhna/11.jpg";
import odhanaTheTraditionalRed12 from "../assets/images/photography/odhna/12.jpg";
import odhanaTheTraditionalRed13 from "../assets/images/photography/odhna/13.jpg";
import odhanaTheTraditionalRed14 from "../assets/images/photography/odhna/14.jpg";
import odhanaTheTraditionalRed15 from "../assets/images/photography/odhna/15.jpg";
import odhanaTheTraditionalRed16 from "../assets/images/photography/odhna/16.jpg";
import odhanaTheTraditionalRed17 from "../assets/images/photography/odhna/17.jpg";
import odhanaTheTraditionalRed18 from "../assets/images/photography/odhna/18.jpg";
import odhanaTheTraditionalRed19 from "../assets/images/photography/odhna/19.jpg";
import odhanaTheTraditionalRed20 from "../assets/images/photography/odhna/20.jpg";

// mermaidsBatheHere
import mermaidsBatheHere1 from "../assets/images/photography/mermaids_bathe_here/1.jpg";
import mermaidsBatheHere2 from "../assets/images/photography/mermaids_bathe_here/2.jpg";
import mermaidsBatheHere3 from "../assets/images/photography/mermaids_bathe_here/3.jpg";
import mermaidsBatheHere4 from "../assets/images/photography/mermaids_bathe_here/4.jpg";
import mermaidsBatheHere5 from "../assets/images/photography/mermaids_bathe_here/5.jpg";
import mermaidsBatheHere6 from "../assets/images/photography/mermaids_bathe_here/6.jpg";
import mermaidsBatheHere7 from "../assets/images/photography/mermaids_bathe_here/7.jpg";
import mermaidsBatheHere8 from "../assets/images/photography/mermaids_bathe_here/8.jpg";
import mermaidsBatheHere9 from "../assets/images/photography/mermaids_bathe_here/9.jpg";
import mermaidsBatheHere10 from "../assets/images/photography/mermaids_bathe_here/10.jpg";
import mermaidsBatheHere11 from "../assets/images/photography/mermaids_bathe_here/11.jpg";

//dancingInTheRainforest
import dancingInTheRainforest1 from "../assets/images/photography/dancing_in_the_rainforest/1.jpg";
import dancingInTheRainforest2 from "../assets/images/photography/dancing_in_the_rainforest/2.jpg";
import dancingInTheRainforest3 from "../assets/images/photography/dancing_in_the_rainforest/3.jpg";
import dancingInTheRainforest4 from "../assets/images/photography/dancing_in_the_rainforest/4.jpg";
import dancingInTheRainforest5 from "../assets/images/photography/dancing_in_the_rainforest/5.jpg";
import dancingInTheRainforest6 from "../assets/images/photography/dancing_in_the_rainforest/6.jpg";
import dancingInTheRainforest7 from "../assets/images/photography/dancing_in_the_rainforest/7.jpg";
import dancingInTheRainforest8 from "../assets/images/photography/dancing_in_the_rainforest/8.jpg";
import dancingInTheRainforest9 from "../assets/images/photography/dancing_in_the_rainforest/9.jpg";
import dancingInTheRainforest10 from "../assets/images/photography/dancing_in_the_rainforest/10.jpg";
import dancingInTheRainforest11 from "../assets/images/photography/dancing_in_the_rainforest/11.jpg";
import dancingInTheRainforest12 from "../assets/images/photography/dancing_in_the_rainforest/12.jpg";

//beautyAndBeach
import beautyAndBeach1 from "../assets/images/photography/beauty_and_the_beach/1.jpg";
import beautyAndBeach2 from "../assets/images/photography/beauty_and_the_beach/2.jpg";
import beautyAndBeach3 from "../assets/images/photography/beauty_and_the_beach/3.jpg";
import beautyAndBeach4 from "../assets/images/photography/beauty_and_the_beach/4.jpg";
import beautyAndBeach5 from "../assets/images/photography/beauty_and_the_beach/5.jpg";
import beautyAndBeach6 from "../assets/images/photography/beauty_and_the_beach/6.jpg";
import beautyAndBeach7 from "../assets/images/photography/beauty_and_the_beach/7.jpg";
import beautyAndBeach8 from "../assets/images/photography/beauty_and_the_beach/8.jpg";
import beautyAndBeach9 from "../assets/images/photography/beauty_and_the_beach/9.jpg";
import beautyAndBeach10 from "../assets/images/photography/beauty_and_the_beach/10.jpg";

//janeInMahabstwo
import janeInMahabstwo1 from "../assets/images/photography/jane_in_mahabs_2/1.jpg";
import janeInMahabstwo2 from "../assets/images/photography/jane_in_mahabs_2/2.jpg";
import janeInMahabstwo3 from "../assets/images/photography/jane_in_mahabs_2/3.jpg";
import janeInMahabstwo4 from "../assets/images/photography/jane_in_mahabs_2/4.jpg";
import janeInMahabstwo5 from "../assets/images/photography/jane_in_mahabs_2/5.jpg";
import janeInMahabstwo6 from "../assets/images/photography/jane_in_mahabs_2/6.jpg";
import janeInMahabstwo7 from "../assets/images/photography/jane_in_mahabs_2/7.jpg";
import janeInMahabstwo8 from "../assets/images/photography/jane_in_mahabs_2/8.jpg";
import janeInMahabstwo9 from "../assets/images/photography/jane_in_mahabs_2/9.jpg";
import janeInMahabstwo10 from "../assets/images/photography/jane_in_mahabs_2/10.jpg";

//janeInMahabs
import janeInMahabs1 from "../assets/images/photography/jane_in_mahabs/1.jpg";
import janeInMahabs2 from "../assets/images/photography/jane_in_mahabs/2.jpg";
import janeInMahabs3 from "../assets/images/photography/jane_in_mahabs/3.jpg";
import janeInMahabs4 from "../assets/images/photography/jane_in_mahabs/4.jpg";
import janeInMahabs5 from "../assets/images/photography/jane_in_mahabs/5.jpg";
import janeInMahabs6 from "../assets/images/photography/jane_in_mahabs/6.jpg";
import janeInMahabs7 from "../assets/images/photography/jane_in_mahabs/7.jpg";
import janeInMahabs8 from "../assets/images/photography/jane_in_mahabs/8.jpg";
import janeInMahabs9 from "../assets/images/photography/jane_in_mahabs/9.jpg";
import janeInMahabs10 from "../assets/images/photography/jane_in_mahabs/10.jpg";

//lostInBeautyForest
import lostInBeautyForest1 from "../assets/images/photography/lost_in_beauty_forest/1.jpg";
import lostInBeautyForest2 from "../assets/images/photography/lost_in_beauty_forest/2.jpg";
import lostInBeautyForest3 from "../assets/images/photography/lost_in_beauty_forest/3.png";
import lostInBeautyForest4 from "../assets/images/photography/lost_in_beauty_forest/4.jpg";
import lostInBeautyForest5 from "../assets/images/photography/lost_in_beauty_forest/5.jpg";
import lostInBeautyForest6 from "../assets/images/photography/lost_in_beauty_forest/6.jpg";
import lostInBeautyForest7 from "../assets/images/photography/lost_in_beauty_forest/7.jpg";
import lostInBeautyForest8 from "../assets/images/photography/lost_in_beauty_forest/8.jpg";
import lostInBeautyForest9 from "../assets/images/photography/lost_in_beauty_forest/9.jpg";
import lostInBeautyForest10 from "../assets/images/photography/lost_in_beauty_forest/10.jpg";
import lostInBeautyForest11 from "../assets/images/photography/lost_in_beauty_forest/11.jpg";
import lostInBeautyForest12 from "../assets/images/photography/lost_in_beauty_forest/12.jpg";
import lostInBeautyForest13 from "../assets/images/photography/lost_in_beauty_forest/13.jpg";
import lostInBeautyForest14 from "../assets/images/photography/lost_in_beauty_forest/14.jpg";

//nierAutomata
import nierAutomata1 from "../assets/images/photography/nier_automata/1.jpg";
import nierAutomata2 from "../assets/images/photography/nier_automata/2.jpg";
import nierAutomata3 from "../assets/images/photography/nier_automata/3.jpg";
import nierAutomata4 from "../assets/images/photography/nier_automata/4.jpg";
import nierAutomata5 from "../assets/images/photography/nier_automata/5.jpg";
import nierAutomata6 from "../assets/images/photography/nier_automata/6.jpg";
import nierAutomata7 from "../assets/images/photography/nier_automata/7.jpg";
import nierAutomata8 from "../assets/images/photography/nier_automata/8.jpg";
import nierAutomata9 from "../assets/images/photography/nier_automata/9.jpg";
import nierAutomata10 from "../assets/images/photography/nier_automata/10.jpg";
import nierAutomata11 from "../assets/images/photography/nier_automata/11.jpg";
import nierAutomata12 from "../assets/images/photography/nier_automata/12.jpg";
import nierAutomata13 from "../assets/images/photography/nier_automata/13.jpg";

// Project Images
import { ReactComponent as SumofameImage } from "../assets/images/projects/sumofame.svg";
import fanollyWoodImg from "../assets/images/projects/Fanollywood_New_Logo.png";
import btImg from "../assets/images/projects/BT.png";
import ktImg from "../assets/images/projects/KT.png";
import fanollyLionImg from "../assets/images/projects/FanollyLION.png";
//---
// project page images
import fanollywoodLogo from "../assets/images/projects/fanollywoodIcon.png";
import cheesieLogo from "../assets/images/projects/cheesieIcon.png";
import washiLogo from "../assets/images/projects/washiIcon.png";
import cheeseBanner from "../assets/images/projects/cheeseBanner.png";
import washiBanner from "../assets/images/projects/washiBanner.png";
import { ReactComponent as SumofameLogo } from "../assets/images/projects/sumofameIcon.svg";

//video images
import odhnaViImg from "../assets/images/video_thumbs/odhna-vi.jpg";

export const SVGImages = {
    AkashLogo: AkashLogo,
    AkashLogoBlack,
    InstagramDarkLogo: InstagramDarkLogo,
    LinkedInDarkLogo: LinkedInDarkLogo,
    PlayIcon,
    NextArrow,
    LinkedInDarkLight,
    HomeIcon,
    NextArrowLight,
    InstagramLightLogo,
    LinkedinLightLogo,
    UpArrow,
    SendIcon,
    CloseCircleIcon,
    ProjectLinkIcon,
    ProjectLinkHoverIcon,
    VolumeMuteIcon,
    VolumeUnMuteIcon,
    PauseCircleFill,
    PlayCircleFill,
    RightArrow,
    FullscreenIcon,
    FullscreenExitIcon,
    SumofameImage,
    SumofameLogo
};

export const Images = {
    namansteImg,
    businessCardImg,
    indianExpressImg,
    indianExpressDarkImg,
    dtNextImg,
    dtNextDarkImg,
    lightLinerImg,
    darkLinerBgImg,
    filmBanner,
    sidebarBackground,
    projectsBanner,
    snaplarkLogo,
    projectImage,
    fanolly,
    washi,
    sumofame,
    washiImg,
    snaplarkImg,
    cheesieImg,
    ddMenuBgImg,
    ddBgImg,
    ddBgReverseImg,
    snaplarkBgImg,
    closeIcon,
    shapeL1Img,
    shapeL2Img,
    fireImg,
    sumofameImg,
    sumoBg1,
    sumoBg2,
    sumoIcon,
    arrowImg,
    cloud1,
    cloud2,
    cloud3,
    cloud4,
    cloud5,
    akashfaceImg,
    beautyAndBeachThumb,
    janeInMahabsThumb,
    janeInMahabstwo,
    lostInBeautyForestThumb,
    nierAutomataThumb,
    theRealDealThumb,
    thefuture,
    theangelicbird,
    mermaidsBatheHereThumb,
    dancingInTheRainforestThumb,
    picnicInTheJungleThumb,
    odhanaTheTraditionalRedThumb,
    prettyGirlThumb,
    fanollyWoodImg,
    btImg,
    ktImg,
    fanollyLionImg,
    sumofameSlideImg,
    fanollySlideImg,
    washibgimg,
    cheeisebgimg,
    fanollywoodLogo,
    cheesieLogo,
    washiLogo,
    cheeseBanner,
    washiBanner,
    washiSlideImg,
    snaplarkSlideImg,
    cheesieSlideImg
};

export const VideoImages = {
    odhnaViImg
};
export const PhotographyImages = {
    beautyAndBeach1,
    beautyAndBeach2,
    beautyAndBeach3,
    beautyAndBeach4,
    beautyAndBeach5,
    beautyAndBeach6,
    beautyAndBeach7,
    beautyAndBeach8,
    beautyAndBeach9,
    beautyAndBeach10,
    janeInMahabstwo1,
    janeInMahabstwo2,
    janeInMahabstwo3,
    janeInMahabstwo4,
    janeInMahabstwo5,
    janeInMahabstwo6,
    janeInMahabstwo7,
    janeInMahabstwo8,
    janeInMahabstwo9,
    janeInMahabstwo10,
    janeInMahabs1,
    janeInMahabs2,
    janeInMahabs3,
    janeInMahabs4,
    janeInMahabs5,
    janeInMahabs6,
    janeInMahabs7,
    janeInMahabs8,
    janeInMahabs9,
    janeInMahabs10,
    lostInBeautyForest1,
    lostInBeautyForest2,
    lostInBeautyForest3,
    lostInBeautyForest4,
    lostInBeautyForest5,
    lostInBeautyForest6,
    lostInBeautyForest7,
    lostInBeautyForest8,
    lostInBeautyForest9,
    lostInBeautyForest10,
    lostInBeautyForest11,
    lostInBeautyForest12,
    lostInBeautyForest13,
    lostInBeautyForest14,
    nierAutomata1,
    nierAutomata2,
    nierAutomata3,
    nierAutomata4,
    nierAutomata5,
    nierAutomata6,
    nierAutomata7,
    nierAutomata8,
    nierAutomata9,
    nierAutomata10,
    nierAutomata11,
    nierAutomata12,
    nierAutomata13,
    dancingInTheRainforest1,
    dancingInTheRainforest2,
    dancingInTheRainforest3,
    dancingInTheRainforest4,
    dancingInTheRainforest5,
    dancingInTheRainforest6,
    dancingInTheRainforest7,
    dancingInTheRainforest8,
    dancingInTheRainforest9,
    dancingInTheRainforest10,
    dancingInTheRainforest11,
    dancingInTheRainforest12,
    mermaidsBatheHere1,
    mermaidsBatheHere2,
    mermaidsBatheHere3,
    mermaidsBatheHere4,
    mermaidsBatheHere5,
    mermaidsBatheHere6,
    mermaidsBatheHere7,
    mermaidsBatheHere8,
    mermaidsBatheHere9,
    mermaidsBatheHere10,
    mermaidsBatheHere11,
    odhanaTheTraditionalRed1,
    odhanaTheTraditionalRed2,
    odhanaTheTraditionalRed3,
    odhanaTheTraditionalRed4,
    odhanaTheTraditionalRed5,
    odhanaTheTraditionalRed6,
    odhanaTheTraditionalRed7,
    odhanaTheTraditionalRed8,
    odhanaTheTraditionalRed9,
    odhanaTheTraditionalRed10,
    odhanaTheTraditionalRed11,
    odhanaTheTraditionalRed12,
    odhanaTheTraditionalRed13,
    odhanaTheTraditionalRed14,
    odhanaTheTraditionalRed15,
    odhanaTheTraditionalRed16,
    odhanaTheTraditionalRed17,
    odhanaTheTraditionalRed18,
    odhanaTheTraditionalRed19,
    odhanaTheTraditionalRed20,
    picnicInTheJungle1,
    picnicInTheJungle2,
    picnicInTheJungle3,
    picnicInTheJungle4,
    picnicInTheJungle5,
    picnicInTheJungle6,
    picnicInTheJungle7,
    picnicInTheJungle8,
    picnicInTheJungle9,
    picnicInTheJungle10,
    picnicInTheJungle11,
    picnicInTheJungle12,
    picnicInTheJungle13,
    picnicInTheJungle14,
    picnicInTheJungle15,
    prettyGirl1,
    prettyGirl2,
    prettyGirl3,
    prettyGirl4,
    prettyGirl5,
    prettyGirl6,
    prettyGirl7,
    prettyGirl8,
    prettyGirl9,
    prettyGirl10,
    prettyGirl11,
    prettyGirl12,
    prettyGirl13,
    prettyGirl14,
    prettyGirl15,
    prettyGirl16,
    theRealDeal1,
    theRealDeal2,
    theRealDeal3,
    theRealDeal4,
    theRealDeal5,
    theRealDeal6,
    theRealDeal7,
    theRealDeal8,
    theRealDeal9,
    theRealDeal10,
    theRealDeal11,
    theRealDeal12,
    theRealDeal13,
    theRealDeal14,
    theRealDeal15,
    thefuture1,
    thefuture2,
    thefuture3,
    thefuture4,
    thefuture5,
    thefuture6,
    thefuture7,
    thefuture8,
    thefuture9,
    thefuture10,
    thefuture11,
    thefuture12,
    thefuture13,
    thefuture14,
    theangelicbird1,
    theangelicbird2,
    theangelicbird3,
    theangelicbird4,
    theangelicbird5,
    theangelicbird6,
    theangelicbird7,
    theangelicbird8,
    theangelicbird9,
    theangelicbird10,
    theangelicbird11,
    theangelicbird12,

};
export const Videos = {
    snaplarkVideo
};
