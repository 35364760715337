import React from "react";
import { Container } from "react-bootstrap";
import { FooterBar, FooterContent } from "./footerStyle";
import SocialMedia from "../SocialMedia";

const Footer = ({ variant = "dark", bgimg = true }) => {
    return (
        <FooterBar className={`footer ${variant} ${!bgimg ? "hideBg" : ""}`}>
            <Container>
                <FooterContent className={variant}>
                    <p>2023 Akash Sekar. All Rights Reserved.</p>
                    <SocialMedia variant={variant} inFooter={+true} />
                </FooterContent>
            </Container>
        </FooterBar>
    );
};

export default Footer;
