import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import Fonts from "../../config/fonts";

export const GradientDropdown = styled(Dropdown)`
    ul {
        width: 100%;
        margin-bottom: 0 !important;

        li {
            width: 100%;
        }
    }

    .mouseContainerBox {
        width: 100%;
        height: auto;

        &.dropdown-menu {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate3d(0px, 60px, 0px);
            background-color: transparent;
            border: none !important;
            padding: 0 !important;
        }
    }
    .dropdown-item {
        width: 100%;
        color: #ffffff;
        font-family: ${Fonts.helveticaNeue};
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        padding: 8px 15px 10px;
        border-radius: 16px;
        transition: all 0.1s ease-in-out;

        @media only screen and (max-width: 767px) {
            font-size: 14px;
            padding: 8px 15px 5px;

            svg {
                width: 16px;
                height: auto;
            }
        }

        &:hover {
            background-color: rgba(21, 21, 24, 0.56);
        }

        &:focus {
            background-color: unset !important;
        }
    }
`;
