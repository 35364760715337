import { Col, Container, Row } from "react-bootstrap";
import { Images, Videos } from "../../../config/images";
import { AboutThisProjectContainer, ContactButtonsContainer, FeaturesContainer, ProjectTitleContainer, VisitWebsiteButton } from "./styles";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";

const SnaplarkPageContent = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cardVariant = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.5,
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        },
        inactive: {
            opacity: 0,
            y: 10,
            transition: {
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        }
    };

    const eachCardVariants = {
        offscreen: {
            y: 10
        },
        onscreen: {
            y: 0,
            //rotate: -10,
            transition: {
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        }
    };

    const viewPortData = {
        once: true,
        amount: 0.1
    };

    return (
        <motion.div variants={cardVariant}>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <Container>
                    <ProjectTitleContainer>
                        <img src={Images.snaplarkLogo} alt="project-logo" />
                        <p>Snaplark</p>
                    </ProjectTitleContainer>
                </Container>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <FeaturesContainer>
                    <Container>
                        <Row>
                            <Col md={6} lg={7}>
                                <div className="videoPlayer">
                                    <video loop autoPlay muted>
                                        <source src={Videos.snaplarkVideo} type="video/mp4" />
                                        <source src={Videos.snaplarkVideo} type="video/mp4" />
                                    </video>
                                </div>
                            </Col>
                            <Col md={6} lg={5}>
                                <div className="flex">
                                    <h3>Unique Features:</h3>
                                    <ul>
                                        <li>Introducing a revolutionary screen sharing experience</li>
                                        <li>Cutting-edge media management portal</li>
                                        <li>Seamless cloud-based uploading system</li>
                                        <li>Transforming the conventional approach to screen capturing</li>
                                        <li>Effortlessly lightweight and user-friendly optimization</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </FeaturesContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <AboutThisProjectContainer>
                    <Container>
                        <h3>About this project</h3>
                        <p>
                            Snaplark revolutionizes the process of screensharing and significantly reduces meeting duration with its
                            innovative screenshot video and GIF recording software. Having amassed an impressive number of over a million
                            media uploads, Snaplark has firmly established itself as the preferred choice for regular PC & Mac users. By
                            addressing the shortcomings of traditional screenshot apps, Snaplark incorporates all the highly sought-after
                            features, providing it with a distinctive advantage over competing software options.
                        </p>
                    </Container>
                </AboutThisProjectContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <Container>
                    <ContactButtonsContainer>
                        <Link to="https://www.snaplark.com/">
                            <VisitWebsiteButton isdotbtn={+true}>
                                <span>Visit Website</span>
                            </VisitWebsiteButton>
                        </Link>
                        <a href="mailto:support@snaplark.com">support@snaplark.com</a>
                    </ContactButtonsContainer>
                </Container>
            </motion.div>
        </motion.div>
    );
};

export default SnaplarkPageContent;
