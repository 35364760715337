import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { motion } from "framer-motion";
import { Images, SVGImages } from "../../config/images";
import {
    CategoryCard,
    PhotoGraphyContainer,
    FloatingButton,
    MasonaryContainer,
    Sidebar,
    SingleMasonaryContainer,
    ImageCard,
    LoadingOverlayBox
} from "./styles";
import { CategoryList } from "../../utils/constants";

import shuffle from "lodash/shuffle";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import SocialMedia from "../../components/SocialMedia";
import LinerBackground from "../../components/LinerBackground";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import ConditionalContent from "../../components/ConditionalContent";

const Photography = () => {
    const [categoryList, setCategoryList] = useState(shuffle(CategoryList));
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(-1);
    const [filterImgs, setFilterImgs] = useState([]);
    const [lightBoxImgs, setLightBoxImgs] = useState([{ src: "" }]);
    const [open, setOpen] = useState({ show: false, imageID: -1 });

    const navigate = useNavigate();

    useEffect(() => {
        const scrollContainer = document.querySelector(".allList");
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.clientHeight;

            if (scrollTop + windowHeight >= documentHeight) {
                setCategoryList((prev) => [...prev, ...CategoryList]);
            }
        };

        scrollContainer.addEventListener("scroll", handleScroll);

        return () => {
            scrollContainer.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const ele = document.querySelector(".singleList");
        ele.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [filterImgs]);

    const viewAllHandler = () => {
        setIsSelected(-1);
        setFilterImgs([]);
    };
    const signleListHandler = (cateId, catimages) => {
        setIsSelected(cateId);
        const lightBoxImages = [];
        const cateIndex = CategoryList.findIndex((x) => x.id === cateId);
        setFilterImgs([...CategoryList[cateIndex].images]);
        var len = catimages.length;
        for (var i = 0; i < len; i++) {
            lightBoxImages.push({
                src: catimages[i]
            });
        }
        setLightBoxImgs(lightBoxImages);
    };

    const cardVariant = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.5,
                type: "spring",
                bounce: 0.5,
                duration: 1
            }
        },
        inactive: {
            opacity: 0,
            y: 10,
            transition: {
                type: "spring",
                bounce: 0.5,
                duration: 1
            }
        }
    };

    const eachCardVariants = {
        offscreen: {
            y: 300
        },
        onscreen: {
            y: 0,
            //rotate: -10,
            transition: {
                type: "spring",
                bounce: 0.5,
                duration: 1
            }
        }
    };

    const viewPortData = {
        once: true,
        amount: 0.1
    };

    return (
        <PhotoGraphyContainer>
            <ConditionalContent $minWidth={769}>
                <FloatingButton onClick={() => setIsOpen((prev) => !prev)}>
                    <span></span>
                    <span className="centerLine"></span>
                    <span></span>
                </FloatingButton>
            </ConditionalContent>
            <Sidebar $isOpen={isOpen}>
                <div className="header">
                    <div onClick={() => navigate("/")}>
                        <Image src={Images.akashfaceImg} className="siteLogo" fluid />
                    </div>
                    <ConditionalContent $minWidth={769} className="closeIcon">
                        <img src={Images.closeIcon} width={32} height={32} alt="close-icon" onClick={() => setIsOpen((prev) => !prev)} />
                    </ConditionalContent>
                </div>
                <ul className="scroll scroll-ul">
                    <li className={`${isSelected === -1 ? "selected" : ""}`} onClick={() => viewAllHandler()}>
                        <span>View All</span>
                        <SVGImages.RightArrow />
                    </li>
                    {CategoryList.map((item) => {
                        return (
                            <li
                                key={item.id}
                                className={isSelected === item.id ? "selected" : ""}
                                onClick={() => signleListHandler(item.id, item.images)}
                            >
                                <span>{item.name}</span>
                                <SVGImages.RightArrow />
                            </li>
                        );
                    })}
                </ul>

                <div className="footer">
                    <SocialMedia />
                </div>
            </Sidebar>
            <MasonaryContainer className={`allList cardListing ${isSelected === -1 ? "isActive" : ""}`}>
                <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1025: 2, 1200: 3, 1600: 4 }}>
                    <Masonry gutter="20px">
                        {categoryList?.map((category, index) => (
                            <CategoryCard key={index} ids={category.id} onClick={() => signleListHandler(category.id, category.images)}>
                                <img src={category.thumbnail} alt="category" />
                                <p>{category.name}</p>
                            </CategoryCard>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </MasonaryContainer>
            <SingleMasonaryContainer className={`singleList cardListing ${isSelected >= 1 ? "isActive" : ""}`}>
                <motion.div variants={cardVariant}>
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1, 1300: 3, 1600: 4 }}>
                        <Masonry gutter="20px">
                            {filterImgs?.map((imgItem, index) => (
                                <ImageCard
                                    variants={eachCardVariants}
                                    key={index}
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={viewPortData}
                                    onClick={() => setOpen({ show: true, imageID: index })}
                                >
                                    <MyImage src={imgItem} />
                                </ImageCard>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </motion.div>
            </SingleMasonaryContainer>
            <Lightbox
                styles={{ container: { backgroundColor: "#15161acc" } }}
                open={open.show}
                index={open.imageID}
                close={() => setOpen({ show: false, imageID: -1 })}
                slides={lightBoxImgs}
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
            <LinerBackground style={{ position: "fixed", opacity: 0.4 }} />
        </PhotoGraphyContainer>
    );
};

export const MyImage = ({ src }) => {
    const [loading, setLoading] = useState(true);
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative"
            }}
        >
            <Image
                src={src}
                style={{
                    display: loading ? "none" : "block",
                    width: "100%",
                    animation: "fadeIn 0.5s"
                }}
                onLoad={(e) => {
                    setLoading(false);
                }}
            />
            <LoadingOverlayBox
                style={{
                    display: loading ? "block" : "none"
                }}
            />
        </div>
    );
};

export default Photography;
