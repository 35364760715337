import React from "react";
import Slider from "react-slick";

import { BannerSlider, BannerWrapper, DropDownContainer, TextContainer } from "./bannerStyles";
import { Dropdown, Image } from "react-bootstrap";
import { SVGImages } from "../../../config/images";
import DropDownBox from "../../../components/DropDownBox";
import GradientButton from "../../../components/GradientButton";
import { Link } from "react-router-dom";
import LinerBackground from "../../../components/LinerBackground";
import { projectSlideData } from "../../../utils/projectTabs";

const BannerSection = () => {
    const settings = {
        className: "center",
        swipeToSlide: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        arrows: false,
        infinite: true,
        pauseOnHover: false,
        centerMode: true, //added
        centerPadding: "25%", //added
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "25%"
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: "15%"
                }
            },
            {
                breakpoint: 600,
                settings: {
                    centerPadding: "5%",
                    speed: 2000,
                    autoplaySpeed: 2000
                }
            }
        ]
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <GradientButton
            isdotbtn={+true}
            // ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </GradientButton>
    ));

    const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        return (
            <GradientButton
                isBox={+true}
                isdotbtn={+true}
                // ref={ref}
                style={style}
                mpClassName={className}
                //className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children).map((child, index) => {
                        return <li key={index + "a1"}>{child}</li>;
                    })}
                </ul>
            </GradientButton>
        );
    });

    return (
        <>
            <BannerWrapper id="banner-section">
                <TextContainer>
                    <h2>A step to innovative ideas</h2>
                    <h3>Let's take a progressive step</h3>
                </TextContainer>
                <DropDownContainer>
                    <DropDownBox>
                        <Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic">
                            <span className="ddBtnContent">
                                Contact me <SVGImages.UpArrow />
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={CustomMenu}>
                            <Dropdown.Item href="#/action-1">
                                <span>Contact page</span>
                                <SVGImages.SendIcon width={24} />
                            </Dropdown.Item>
                            <Dropdown.Item href="https://akashkash.com/emails.txt" target="_blank">
                                <span>Emails</span>
                                <SVGImages.SendIcon width={24} />
                            </Dropdown.Item>
                            <Dropdown.Item href="https://akashkash.com/pgpkey.txt" target="_blank">
                                <span>PGP key</span>
                                <SVGImages.SendIcon width={24} />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </DropDownBox>
                    <GradientButton href="https://terminal.akashkash.com" target="_blank" isdotbtn={+true}>
                        <span>Open Terminal</span>
                    </GradientButton>
                </DropDownContainer>
                <LinerBackground style={{ zIndex: 1 }} />
                <BannerSlider>
                    <Slider {...settings}>
                        {projectSlideData.map((project, index) => {
                            return (
                                <ProjectSlide
                                    projectName={project.title}
                                    key={index}
                                    projectImg={project.projectImage}
                                    projectLink={project.link}
                                />
                            );
                        })}
                    </Slider>
                </BannerSlider>
            </BannerWrapper>
        </>
    );
};

const ProjectSlide = ({ projectName, projectImg, projectLink }) => {
    return (
        <div className="image-slide" title={projectName}>
            <div className="slideContainer">
                <Image src={projectImg} title={projectName} />
            </div>
            <ProjectLinkBtn link={projectLink} title={projectName} />
        </div>
    );
};

const ProjectLinkBtn = ({ link, title }) => {
    let rotate = 187.16;
    const projectLinks = document.querySelectorAll(".projectLink");
    projectLinks.forEach((projectLink) => {
        const linkIconWrapper = projectLink.querySelector(".linkIconWrapper");
        const mouseMoveHandler = (event) => {
            let x = linkIconWrapper.getBoundingClientRect().left + linkIconWrapper.clientWidth / 2;
            let y = linkIconWrapper.getBoundingClientRect().top + linkIconWrapper.clientHeight / 2;

            let radian = Math.atan2(event.pageX - x, event.pageY - y);
            rotate = radian * (180 / Math.PI) * -1 + 180;
            linkIconWrapper.style.setProperty("--bgPosDeg", Math.round(rotate) + "deg");
        };

        projectLink.addEventListener("mousemove", mouseMoveHandler);
    });

    return (
        <div className="projectLink wrapper">
            <Link to={`/projects${link}`} className="inner" title={title}>
                <div className="linkIconWrapper" style={{ "--bgPosDeg": Math.round(rotate) + "deg" }}>
                    <SVGImages.SendIcon className="sendIcon" />
                </div>
            </Link>
        </div>
    );
};

export default BannerSection;
