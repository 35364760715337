import styled from "styled-components";

export const SumofameContainer = styled.div`
    .Black {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    }
    .Clouds {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation: cloudsMove 35s linear infinite;

        .Clouds1,
        .Clouds2,
        .Clouds3,
        .Clouds4 {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            // left: 100%;
            right: 100%;
            // overflow: hidden;

            .Cloud {
                max-width: 40vw;
                position: absolute;
                transform: scale(0.8);
            }
        }
        .Clouds2 {
            // left: -100%;
            transform: translateX(100%);
        }
        .Clouds3 {
            // left: -200%;
            transform: translateX(200%);
        }
        .Clouds4 {
            transform: translateX(300%);
        }
    }
    .Arrows {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // animation: arrowsMove 10s linear infinite;

        .Arrow {
            width: 220px;
            max-width: 40vw;
            position: absolute;
            left: -300px;
            // left: calc(-250px - var(--x));
            // top: var(--y);
            // top: 0;
            animation: arrowMove 7s linear infinite;

            @keyframes arrowMove {
                0% {
                    // transform: translate(var(--x), var(--y));
                    transform: translateX(0%);
                    // left: 100%;
                }
                100% {
                    // transform: translate(calc(-100vw - var(--x)), var(--y));
                    // transform: translateX(calc(100vw + 100%));
                    transform: translateX(var(--maxX));
                    // left: -240px;
                }
            }
            .ArrowImg {
                width: 100%;
            }
            .Fire {
                position: absolute;
                width: 35%;
                bottom: 10%;
                right: 10%;
                transform-origin: center 70%;

                animation: fireRotate 0.5s linear alternate infinite;
                @keyframes fireRotate {
                    0% {
                        transform: rotate(20deg);
                    }
                    100% {
                        transform: rotate(-20deg);
                    }
                }
            }
        }
    }
    .MainLogo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        max-width: 80vw;
    }
    .SumoIcon {
        position: absolute;
        bottom: 2.5vw;
        left: 0;
        width: 35%;
    }
    .BottomBg {
        position: absolute;
        bottom: 0;

        &.Bg1 {
            left: 0;
            width: 65%;
        }
        &.Bg2 {
            right: 0;
            width: 42%;
        }
    }
`;
