import { Col, Container, Image, Row } from "react-bootstrap";
import { Images } from "../../../config/images";
import { AboutThisProjectContainer, ContactButtonsContainer, FeaturesContainer, ProjectTitleContainer, VisitWebsiteButton } from "./styles";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";

const WashiPageContent = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cardVariant = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.5,
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        },
        inactive: {
            opacity: 0,
            y: 10,
            transition: {
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        }
    };

    const eachCardVariants = {
        offscreen: {
            y: 10
        },
        onscreen: {
            y: 0,
            //rotate: -10,
            transition: {
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        }
    };

    const viewPortData = {
        once: true,
        amount: 0.1
    };

    return (
        <motion.div variants={cardVariant}>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <Container>
                    <ProjectTitleContainer>
                        <img src={Images.washiLogo} alt="project-logo" />
                        <p>Washi</p>
                    </ProjectTitleContainer>
                </Container>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <FeaturesContainer>
                    <Container>
                        <Row>
                            <Col md={12} lg={12}>
                                <Image src={Images.washiBanner} alt="project" fluid />
                            </Col>
                        </Row>
                    </Container>
                </FeaturesContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <AboutThisProjectContainer>
                    <Container>
                        <h3>About this project</h3>
                        <p>
                            Washi is a special solution that helps clean fruits and vegetables really well by removing harmful pesticides.
                            Experts recommend using Washi instead of plain tap water to soak fruits and veggies that have their skin on.
                            Washi works by causing a chemical reaction that gets rid of pesticides on the surface of the produce. Many
                            pesticides used by farmers and food producers are made of oil, but Washi's alkaline properties help break them
                            down.
                        </p>
                        <p>
                            These chemicals are used all over the world to protect crops from bugs and weeds. Recent research shows that
                            apples, peaches, nectarines, cucumbers, peppers, spinach, and potatoes are some of the fruits and vegetables
                            with the most pesticide residue. Learn more on how Washi can be your daily essential in your kitchen at{" "}
                            <Link to="https://washi.in/">https://washi.in/</Link>
                        </p>
                    </Container>
                </AboutThisProjectContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <Container>
                    <ContactButtonsContainer>
                        <Link to="https://www.washi.in/">
                            <VisitWebsiteButton isdotbtn={+true}>
                                <span>Visit Website</span>
                            </VisitWebsiteButton>
                        </Link>
                    </ContactButtonsContainer>
                </Container>
            </motion.div>
        </motion.div>
    );
};

export default WashiPageContent;
