import React, { useEffect, useState } from "react";
import { BusinessCardContent, ContactUsContainer, FeaturedInContent, FormContainer, HeadingContent } from "./contactUsStyles";
import { Images } from "../../../config/images";
import { Alert, Col, Container, Form, Image, Row } from "react-bootstrap";
import ShadowButton from "../../../components/ShadowButton";
import Footer from "../../../components/Footer";
import MFBackground from "../../../components/MFBackground";
import { Link } from "react-router-dom";
import axios from "axios";

const ContactUsSection = ({ isAcitve }) => {
    useEffect(() => {
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");
        scrollTextEle.removeAttribute("style");
        scrollLineEle.removeAttribute("style");
    }, [isAcitve]);

    return (
        <>
            <ContactUsContainer id="contact-us-section">
                <Container>
                    <div className="innerContainer">
                        <Row>
                            <Col lg={8}>
                                <HeadingContent>
                                    <div className="innerContent">
                                        <h3>Say Vannakam!</h3>
                                        <Image src={Images.namansteImg} width={80} />
                                    </div>
                                    <p>Enter your collaboration details / feedback below and I'll get back to you as soon as I can!</p>
                                </HeadingContent>
                                <ContactForm />
                            </Col>
                            <Col lg={4}>
                                <BusinessCardContent>
                                    <Image src={Images.akashfaceImg} fluid />
                                </BusinessCardContent>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <FeaturedInContent>
                                    <p>Featured In:</p>
                                    <div className="innerContent">
                                        <Link to="#" target="_blank">
                                            <Image src={Images.indianExpressDarkImg} width={300} fluid />
                                        </Link>
                                        <Link to="#" target="_blank">
                                            <Image src={Images.dtNextDarkImg} width={234} fluid />
                                        </Link>
                                    </div>
                                </FeaturedInContent>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Footer variant="light" bgimg={+false} />
                <MFBackground style={{ zIndex: 1 }} />
            </ContactUsContainer>
        </>
    );
};

const ContactForm = () => {
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [message, setMessage] = useState("");

    const reset = () => {
        setName("");
        setEmail("");
        setCountry("");
        setContactNumber("");
        setMessage("");
        setLoading(false);
    };

    const sendEmail = () => {
        setLoading(true);

        const content = `
         Name: ${name}
         Email: ${email}
         Country: ${country}
         Contact Number: ${contactNumber}
         Message: ${message}
        `;

        const emailData = {
            sender: { email: "no-reply@akashkash.com" },
            to: [{ email: "hello@akashkash.com" }],
            subject: "New Contact Form Submission",
            textContent: content,
        };

        const ackEmailData = {
            sender: { email: "no-reply@akashkash.com" },
            to: [{ email: email }],
            subject: "AkashKash: We've received your email",
            textContent: `
            Thank you for reaching out to Akash. We've acknowledged your message and assure you that our team is already addressing your inquiry.

            You can expect to receive a response from us shortly.

            Best regards,
            AkashKash`,
        };

        axios
            .post("https://api.brevo.com/v3/smtp/email", emailData, {
                headers: {
                    "Content-Type": "application/json",
                    "api-key": "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",
                },
            })
            .then(() => {
                setIsSuccess(true);
                reset();
            })
            .catch((err) => {
                console.error("Error sending email:", err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios
            .post("https://api.brevo.com/v3/smtp/email", ackEmailData, {
                headers: {
                    "Content-Type": "application/json",
                    "api-key": "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",
                },
            })
            .then(() => {
                console.log("Acknowledgment email sent");
            })
            .catch((err) => {
                console.error("Error sending acknowledgment email:", err);
            });
    };

    return (
        <FormContainer>
            <Form>
                {isSuccess && (
                    <Alert dismissible={true} variant={"success"} style={{ color: "#0a3622", backgroundColor: "#d1e7dd" }}>
                        Your message has been sent successfully!
                    </Alert>
                )}
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control type="text" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Control
                            type="email"
                            placeholder="Enter Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Control type="text" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Control
                            type="text"
                            placeholder="Contact Number"
                            value={contactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group className="mb-3" controlId="formGridMessage">
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Message:"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="formGridSubmitButton">
                        <ShadowButton variant="light" className={"mx-auto submitBtn " + (loading ? "disable" : "")} onClick={sendEmail}>
                            {loading && (
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginRight: "8px" }}
                                >
                                    <path
                                        fill="#000000"
                                        d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                                    >
                                        <animateTransform
                                            attributeName="transform"
                                            dur="0.75s"
                                            repeatCount="indefinite"
                                            type="rotate"
                                            values="0 12 12;360 12 12"
                                        />
                                    </path>
                                </svg>
                            )}
                            <span>Submit</span>
                        </ShadowButton>
                    </Form.Group>
                </Row>
            </Form>
        </FormContainer>
    );
};

export default ContactUsSection;