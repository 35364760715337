import { useEffect } from "react";
import { useState } from "react";

const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    useEffect(() => {
        let isMoving = false;
        const updateMousePosition = (ev) => {
            if (!isMoving) {
                isMoving = true;
                setMousePosition({ x: ev.clientX, y: ev.clientY });
                setTimeout(() => (isMoving = false), 50);
            }
        };

        window.addEventListener("mousemove", updateMousePosition);

        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);

    return mousePosition;
};

export default useMousePosition;
