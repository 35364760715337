import { Col, Container, Row } from "react-bootstrap";
import { SVGImages } from "../../../config/images";
import { AboutThisProjectContainer, ContactButtonsContainer, FeaturesContainer, ProjectTitleContainer, VisitWebsiteButton } from "./styles";

import { useEffect } from "react";
import { motion } from "framer-motion";

const SumoFamePageContent = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cardVariant = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                staggerChildren: 0.5
            }
        },
        inactive: {
            opacity: 0,
            y: 10,
            transition: {
                duration: 0.5
            }
        }
    };

    const eachCardVariants = {
        offscreen: {
            y: 10
        },
        onscreen: {
            y: 0,
            //rotate: -10,
            transition: {
                type: "spring",
                // bounce: 0.5,
                duration: 1
            }
        }
    };

    return (
        <motion.div variants={cardVariant}>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <Container>
                    <ProjectTitleContainer>
                        <SVGImages.SumofameLogo />
                        <p>Sumofame</p>
                    </ProjectTitleContainer>
                </Container>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <FeaturesContainer>
                    <Container>
                        <Row>
                            <Col md={6} lg={5}>
                                <SVGImages.SumofameImage />
                            </Col>
                            <Col md={6} lg={7}>
                                <div className="flex">
                                    <h3>Unique Features:</h3>
                                    <ul>
                                        <li>Over 50 monthly orders delivered</li>
                                        <li>Promotion of website articles requiring a certain number of clicks (20,000 views/hour)</li>
                                        <li>Unlimited ideas to promote videos and achieve maximum views</li>
                                        <li>Promotion of web articles for a large number of clicks</li>
                                        <li>Highest achieved views: 110 million</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </FeaturesContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <AboutThisProjectContainer>
                    <Container>
                        <h3>About this project</h3>
                        <p>
                            Sumofame is a dynamic media promotion company specializing in the promotion of various forms of content such as
                            videos, including those on platforms like YouTube, web series, movie trailers, website articles, and songs. With
                            a proven track record of successfully promoting Tollywood and Kollywood blockbusters, Sumofame has solidified
                            its position as the top choice for creators seeking to expand their reach and generate increased traffic, views,
                            and streams for their work globally.
                        </p>

                        <p>
                            Our team of meticulous creatives possesses an exceptional understanding of the media landscape, employing
                            result-oriented methods to effectively push your movies, music videos, and social media content. Through
                            consistent and sustained efforts, our promotional campaigns are designed to propel your content to viral
                            success. Trust Sumofame to elevate your creations and capture the attention they deserve.
                        </p>
                    </Container>
                </AboutThisProjectContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <Container>
                    <ContactButtonsContainer>
                        <VisitWebsiteButton isdotbtn={+true}>
                            <span>Visit Website</span>
                        </VisitWebsiteButton>
                        <a href="mailto:support@snaplark.com">support@snaplark.com</a>
                    </ContactButtonsContainer>
                </Container>
            </motion.div>
        </motion.div>
    );
};

export default SumoFamePageContent;
