import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import { FilmContainer } from "./filmSliderStyle";
import { useNavigate } from "react-router-dom";
import HoverVimeoPlayer from "../HoverVideo";
import { videoSliderData } from "../../utils/videoData";

const FilmSlider = ({ slideIndex = 0 }) => {
    const navigator = useNavigate();
    const sliderRef = useRef();

    useEffect(() => {
        sliderRef.current.slickGoTo(slideIndex);
    }, [slideIndex]);

    const settings = {
        className: "film-center",
        swipeToSlide: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 3000,
        arrows: false,
        infinite: true,
        loop: true,
        pauseOnHover: true,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };
    return (
        <>
            <FilmContainer>
                <Slider {...settings} ref={sliderRef}>
                    {videoSliderData.map((videoSlide) => {
                        return (
                            <div
                                key={videoSlide.videoID}
                                className="image-slide"
                                onClick={() =>
                                    navigator("/film-direction/", {
                                        state: { id: videoSlide.videoID[0] }
                                    })
                                }
                            >
                                <HoverVimeoPlayer vid={videoSlide.videoID} />
                            </div>
                        );
                    })}
                </Slider>
            </FilmContainer>
        </>
    );
};

export default FilmSlider;
