import styled from "styled-components";

const ContentContainer = styled.div`
    ${(props) =>
        props.$maxWidth
            ? `@media (max-width: ${props.$maxWidth}px) {
        display: none;
    }`
            : ""}

    ${(props) =>
        props.$minWidth
            ? `@media (min-width: ${props.$minWidth}px) {
        display: none;
    }`
            : ""}
`;

const ConditionalContent = (props) => {
    return <ContentContainer {...props}>{props.children}</ContentContainer>;
};

export default ConditionalContent;
