import React from "react";
import { PhotoGraphyContainer, PhotoGraphyContent, PhotoGraphyBGImge, PortfolioContent, ViewPortfolioBtn } from "./photographyStyles";

// import photoCollageBGImg from "../../../assets/images/photoSectionBgImg@2x.png";
// import { Image } from "react-bootstrap";
import shuffle from "lodash/shuffle";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { CategoryList } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { CategoryCard } from "../../Photography/styles";
import { useEffect } from "react";
import { useState } from "react";

const PhotographySection = ({ isAcitve }) => {
    const [marqueeSpeed, setMarqueeSpeed] = useState(5);
    const categories1 = shuffle(CategoryList);
    const categories2 = categories1.concat(shuffle(CategoryList));
    const categories3 = categories2.concat(shuffle(categories1));

    const navigate = useNavigate();

    useEffect(() => {
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");
        scrollTextEle.removeAttribute("style");
        scrollLineEle.removeAttribute("style");
    }, [isAcitve]);

    useEffect(() => {
        const marqueeSpeedHandler = () => {
            if (document.documentElement.clientWidth < 767) {
                if (marqueeSpeed !== 2) {
                    setMarqueeSpeed(2);
                }
            }
            if (document.documentElement.clientWidth < 600) {
                if (marqueeSpeed !== 1) {
                    setMarqueeSpeed(1);
                }
            }
            if (document.documentElement.clientWidth > 768) {
                if (marqueeSpeed !== 5) {
                    setMarqueeSpeed(5);
                }
            }
        };
        window.addEventListener("resize", marqueeSpeedHandler, true);

        return () => {
            window.removeEventListener("resize", marqueeSpeedHandler, true);
        };
    }, [marqueeSpeed]);
    return (
        <>
            <PhotoGraphyContainer id="photography-section">
                <PhotoGraphyContent>
                    <PortfolioContent>
                        <div>
                            <h3>Visit complete portfolio</h3>
                            <p>EDITORIAL | FASHION</p>
                        </div>
                        <ViewPortfolioBtn isdotbtn={+true} onClick={() => navigate("/photography")}>
                            <span>View All</span>
                        </ViewPortfolioBtn>
                    </PortfolioContent>
                </PhotoGraphyContent>
                <PhotoGraphyBGImge>
                    <Marquee direction="right" speed={marqueeSpeed}>
                        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1300: 4 }}>
                            <Masonry gutter="20px">
                                {categories2?.map((category, index) => (
                                    <CategoryCard key={index + "x"}>
                                        <img src={category.thumbnail} alt="category" />
                                        <p>{category.name}</p>
                                    </CategoryCard>
                                ))}
                                {categories1?.map((category, index) => (
                                    <CategoryCard key={index + "y"}>
                                        <img src={category.thumbnail} alt="category" />
                                        <p>{category.name}</p>
                                    </CategoryCard>
                                ))}
                                {categories3?.map((category, index) => (
                                    <CategoryCard key={index + "z"}>
                                        <img src={category.thumbnail} alt="category" />
                                        <p>{category.name}</p>
                                    </CategoryCard>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Marquee>
                </PhotoGraphyBGImge>
            </PhotoGraphyContainer>
        </>
    );
};

export default PhotographySection;
