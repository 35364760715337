import React, { useState, useEffect } from "react";
import BannerSection from "./BannerSection";
import ProjectSection from "./ProjectSection";
import FilmSection from "./FilmSection";
import PhotographySection from "./PhotographySection";
import ContactUsSection from "./ContactUsSection";
import { animateScroll as scroll } from "react-scroll";
import HorizontalText from "../../components/HorizontalText";
import { useCallback } from "react";

const elemNames = ["banner-section", "project-section", "film-section", "photography-section", "contact-us-section"];

let active = 0;
let scrolled = false,
    scrollingUp = false,
    scrollingDown = false;
var wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

var self = this || {};
self._lastScroll = self._thisScroll = new Date().getTime();

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
    window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
            get: function () {
                supportsPassive = true;
                return supportsPassive;
            }
        })
    );
} catch (e) {}

var wheelOpt = supportsPassive
    ? {
          passive: false
      }
    : false;

function wheelScrollHandler(e, handleChangeTextPosition) {
    var ev = e || window.event;
    self._thisScroll = new Date().getTime();

    var duration = 100;
    var durOffset = self._thisScroll - self._lastScroll;
    durOffset = durOffset < duration ? durOffset : 0;
    scrolled = self._thisScroll - self._lastScroll > (active < elemNames.length - 1 ? duration - 10 : 100);

    if (!e.target.classList.contains("scrollable") && !e.target.closest(".scrollable")) {
        e.preventDefault();
        e.stopPropagation();

        var delta = ev.detail ? ev.detail * -240 : ev.wheelDelta ? ev.wheelDelta : ev.deltaY ? ev.deltaY * 1 * -240 : 0;

        if (e.deltaY < 0 || delta > 0) {
            if (active > 0 && !scrollingUp) {
                self._lastScroll = self._thisScroll;
                if (scrolled) active--;
            }
            scroll.scrollTo(active * window.innerHeight, {
                duration: duration - durOffset
            });
        } else if (e.deltaY > 0 || delta < 0) {
            if (active < elemNames.length - 1 && !scrollingDown) {
                self._lastScroll = self._thisScroll;
                if (scrolled) active++;
            }
            scroll.scrollTo(active * window.innerHeight, {
                duration: duration - durOffset
            });
        }
        handleChangeTextPosition(active * 100);
    }
}

const size = 15; // just change this value;
const maskSizeRatio = size / 10;

const scrollerTexts = [
    { title: "Scroll down", color: "transparent", isActive: true },
    { title: "Projects", color: "#3093ff", isActive: false },
    { title: "Film making", color: "black", isActive: false },
    { title: "Photography", color: "white", isActive: false },
    { title: "Contact Us", color: "black", isActive: false }
];

const Home = () => {
    const maskMinSize = Math.max(window.innerWidth * 0.06 * maskSizeRatio, 80 * maskSizeRatio);
    const [maskSize, setMaskSize] = useState(maskMinSize);
    const [textBottom, setTextBottom] = useState(0);
    const intervalRef = React.useRef(null);
    const [currentSection, setCurrentSection] = useState(0);

    const currentText = scrollerTexts[currentSection];

    useEffect(() => {
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");
        scrollTextEle.removeAttribute("style");
        scrollLineEle.removeAttribute("style");
    }, [currentSection]);

    const handleChangeTextPosition = useCallback((newPosition) => {
        intervalRef.current = setTimeout(() => {
            setCurrentSection(newPosition / 100);
            setTextBottom(newPosition);
        }, 200);
    }, []);

    const onScrollHandler = useCallback(
        (e) => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }

            wheelScrollHandler(e, handleChangeTextPosition);
        },
        [handleChangeTextPosition]
    );

    useEffect(() => {
        if (document.documentElement.clientWidth > 767) {
            window.addEventListener("DOMMouseScroll", onScrollHandler, false);
            window.addEventListener(wheelEvent, onScrollHandler, wheelOpt);

            return () => {
                active = 0;
                window.removeEventListener("DOMMouseScroll", onScrollHandler, false);
                window.removeEventListener(wheelEvent, onScrollHandler, wheelOpt);
                if (intervalRef.current !== null) {
                    clearInterval(intervalRef.current);
                }
            };
        }
    }, [onScrollHandler]);

    return (
        <div className="homepage">
            <BannerSection />
            <ProjectSection isAcitve={currentSection === 1 ? +true : +false} />
            <FilmSection
                isAcitve={currentSection === 2 ? +true : +false}
                maskSize={maskSize}
                setMaskSize={setMaskSize}
                ratio={maskSizeRatio}
            />
            <PhotographySection isAcitve={currentSection === 3 ? +true : +false} />
            <ContactUsSection isAcitve={currentSection === 4 ? +true : +false} />
            {currentText ? (
                <HorizontalText
                    title={currentText.title}
                    bottom={textBottom}
                    color={currentText.color}
                    isAcitve={currentText.isActive}
                ></HorizontalText>
            ) : null}
        </div>
    );
};

export default Home;
