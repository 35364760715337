import React from "react";
import { VimeoWrapper } from "./bgVideoStyle";
import ReactPlayer from "react-player";

const BackgroundVideo = (props) => {
    const { src, muted, autoplay, className, loop, playing, onEnded, onReady, controls } = props;

    return (
        <>
            <VimeoWrapper className="vimeo-wrapper">
                <ReactPlayer
                    className={className}
                    url={src}
                    config={{
                        vimeo: {
                            playerOptions: {
                                autopause: false,
                                muted: muted,
                                autoplay: autoplay,
                                background: false,
                                allowfullscreen: true,
                                loop: loop,
                                responsive: true
                            }
                        }
                    }}
                    muted={muted}
                    allowFullScreen
                    playing={playing}
                    onEnded={onEnded}
                    onReady={onReady}
                    volume={1}
                    controls={controls}
                    width="100%"
                    height="100%"
                />
            </VimeoWrapper>
        </>
    );
};

export default BackgroundVideo;
