import React, { useRef, useState } from "react";
import { LoadingOverlayBox, PausedOverlayBox, VimeoWrapper } from "./bgVideoStyle";
import ReactPlayer from "react-player";
import { Image } from "react-bootstrap";
import { SVGImages } from "../../config/images";

const HoverVimeoPlayer = (props) => {
    const player = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [showPoster, setShowPoster] = useState(true);
    const [isBuffering, setIsBuffering] = useState(false);
    const { vid } = props;

    const onMouseEnterHandler = () => {
        setPlaying(true);
        setShowPoster(false);
    };

    const onMouseLeaveHandler = () => {
        setPlaying(false);
        player.current.seekTo(parseFloat(0), "seconds");
        setShowPoster(true);
    };

    const onBufferHandler = () => {
        setIsBuffering(true);
    };
    const onBufferEndHandler = () => {
        setIsBuffering(false);
        setShowPoster(false);
    };
    const onReadyHandler = () => {
        setIsBuffering(true);
        setShowPoster(true);
    };
    const onPlayHandler = () => {
        setIsBuffering(false);
    };

    return (
        <>
            <VimeoWrapper className="vimeo-hover-wrapper" onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
                <PausedOverlayBox className={!playing && showPoster ? "show" : "hide"}>
                    <Image src={`https://vumbnail.com/${vid[0]}.jpg`} fluid alt="" />
                    <div>
                        <SVGImages.PlayIcon />
                    </div>
                </PausedOverlayBox>
                <LoadingOverlayBox className={isBuffering ? "show" : "hide"}>
                    <div className="overlay" />
                </LoadingOverlayBox>
                <div className={`videoBox ${playing ? "show" : "show"}`}>
                    <ReactPlayer
                        ref={player}
                        url={`https://player.vimeo.com/video/${vid[0]}?h=${vid[1]}`}
                        config={{
                            vimeo: {
                                playerOptions: {
                                    autopause: false,
                                    muted: true,
                                    autoplay: false,
                                    background: false,
                                    allowfullscreen: true,
                                    loop: true,
                                    responsive: true
                                }
                            }
                        }}
                        muted={true}
                        allowFullScreen
                        playing={playing}
                        onPlay={onPlayHandler}
                        onBuffer={onBufferHandler}
                        onBufferEnd={onBufferEndHandler}
                        onReady={onReadyHandler}
                        volume={1}
                        controls={false}
                        width="100%"
                        height="100%"
                    />
                </div>
            </VimeoWrapper>
        </>
    );
};

export default HoverVimeoPlayer;
