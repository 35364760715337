import React, { useEffect, useState } from "react";
import { ProjectBGContent, ProjectBgImage, ProjectContainer, ProjectContent, ProjectItem } from "./projectStyles";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import SnaplarkContent from "./projectContent/SnaplarkContent";
import FanollyContent from "./projectContent/FanollyContent";
import SumofameContent from "./projectContent/SumofameContent";
import { projectData } from "../../../utils/projectTabs";

const ProjectSection = ({ isAcitve }) => {
    const [active, setActive] = useState(projectData[0]);
    const navigate = useNavigate();

    useEffect(() => {
        setActive(projectData[0]);
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");
        scrollTextEle.removeAttribute("style");
        scrollLineEle.removeAttribute("style");
    }, [isAcitve]);

    const projectHoverHandler = (project) => {
        setActive(project);

        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");

        scrollTextEle.style.color = project.color;
        scrollLineEle.style.backgroundColor = project.color;
    };

    return (
        <>
            <ProjectContainer id="project-section">
                <ProjectBGContent>
                    <div className={`snaplarkBg ${active.id === 1 ? "active" : ""}`}>
                        <SnaplarkContent />
                    </div>
                    <div className={`fanollyBg ${active.id === 2 ? "active" : ""}`}>
                        <FanollyContent />
                    </div>
                    <div className={`sumofameBg ${active.id === 3 ? "active" : ""}`}>
                        <SumofameContent />
                    </div>
                    <div className={`cheesieBg ${active.id === 4 ? "active" : ""}`}>
                        <ProjectBgImage projectbgimg={active.projectBgImage !== "" ? active.projectBgImage : ""} />
                    </div>
                    <div className={`washiBg ${active.id === 5 ? "active" : ""}`}>
                        <ProjectBgImage projectbgimg={active.projectBgImage !== "" ? active.projectBgImage : ""} />
                    </div>
                </ProjectBGContent>
                <ProjectContent>
                    {projectData.map((item) => {
                        return (
                            <ProjectItem
                                key={item.id}
                                className={`${active === item ? "active" : ""}`}
                                onClick={() => navigate(`/projects${item.link}`)}
                                projectcolor={item.color}
                            >
                                <Container>
                                    <div className="innerContent" onMouseEnter={() => projectHoverHandler(item)}>
                                        <h2 className="fontDrukWide">{item.title}</h2>
                                        <p className="fontHelvetica">{item.content}</p>
                                    </div>
                                </Container>
                            </ProjectItem>
                        );
                    })}
                </ProjectContent>
            </ProjectContainer>
        </>
    );
};

export default ProjectSection;
