import { Col, Container, Row } from "react-bootstrap";
import { Images } from "../../../config/images";
import {
    AboutThisProjectContainer,
    ContactButtonsContainer,
    FanollyImages,
    FeaturesContainer,
    ProjectTitleContainer,
    VisitWebsiteButton
} from "./styles";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const contents = [
    {
        img: Images.fanollyWoodImg,
        title: "About the Fanolly Network",
        texts: [
            "A fast-growing media network for lovers of cinema, separated into 3 active networks; Bollyticket, Kollyticket and Fanolly pictures. The Indian cinema thrives from the love of its fans and their rave for their favourite movies and stars. Drawing from that, Fanolly got its name; Fans plus – Bollywood, Tollywood and Kollywood. That’s why the overall vision of the brand is to bring complete satisfaction to Indian cinema fans anywhere and everywhere.",

            "Fanolly network is the bedrock of all 3 (Bollyticket, Kollyticket and Fanolly pictures) brands. The maintenance, funding and general overseeing of the brands are taken care of here. Investors also form part of the Fanolly team; they are determined to stick with this brand to make it known and available to all Indian cinema lovers and fans throughout the world."
        ],
        visitLink: "https://www.fanolly.com",
        email: "contact@fanolly.com"
    },
    {
        img: Images.btImg,
        title: "What is Bollyticket?",
        texts: [
            "made explicitly for our vibrant Indian audience. Bollyticket brings you all the latest, hottest news about Bollywood celebrities anytime and anywhere. Made up of a team that is committed to gathering accurate information, the site is daily updated to bring our audience up to date information about their favourite entertainment industry."
        ],
        visitLink: "https://www.bollyticket.com",
        email: "contact@fanolly.com"
    },
    {
        img: Images.ktImg,
        title: "Introducing Kollyticket",
        texts: [
            "Kollyticket brings you all the hottest news from the Tamil cinema industry, Latest trailers, What movies are set to be released? Public reaction to celebrities latest news. Find it all at Kollyticket."
        ],
        visitLink: "https://www.kollyticket.com",
        email: "contact@fanolly.com"
    },
    {
        img: Images.fanollyLionImg,
        title: "Introducing Fanolly Pictures",
        texts: [
            "For the best motion pictures, a group of experienced professionals came together to form a brand that creates top-notch unique quality films. From writing to scripting to producing, we pay close attention to the quality. The young, vibrant professional team at Fanolly has mastered the art of video production; we know exactly what it takes to bring visions to life. From framing to stage lighting, and even on-set direction, we offer diverse skillsets to get the exact results we want."
        ],
        visitLink: "https://www.fanollywood.com",
        email: "contact@fanolly.com"
    }
];

const images = contents.map((c) => c.img);
const textContents = contents.map((c) => c.texts);
const titles = contents.map((c) => c.title);

const FanollyPageContent = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cardVariant = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                staggerChildren: 0.5
            }
        },
        inactive: {
            opacity: 0,
            y: 10,
            transition: {
                duration: 0.5
            }
        }
    };

    const eachCardVariants = {
        offscreen: {
            y: 10
        },
        onscreen: {
            y: 0,
            //rotate: -10,
            transition: {
                type: "spring",
                // bounce: 0.5,
                duration: 1
            }
        }
    };

    return (
        <motion.div variants={cardVariant}>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <Container>
                    <ProjectTitleContainer>
                        <img src={Images.fanollywoodLogo} alt="project-logo" />
                        <p>Fanolly Network</p>
                    </ProjectTitleContainer>
                </Container>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
                <FeaturesContainer>
                    <Container>
                        <Row>
                            <Col md={6} lg={6}>
                                <FanollyImages className="Images">
                                    {images.map((img, i) => {
                                        const classNames = ["ImageContainer"];
                                        const styles = {};
                                        styles.padding = "2rem";
                                        if (i === 3) {
                                            styles.backgroundColor = "#303030";
                                            styles.padding = "2.2rem";
                                        } else if (i === 0) styles.backgroundColor = "#15161a";
                                        else if (i === 1) styles.backgroundColor = "#ED174F";
                                        else if (i === 2) styles.backgroundColor = "#FFF200";
                                        if (i === activeIndex) classNames.push("Active");

                                        return (
                                            <motion.div
                                                key={i}
                                                className={classNames.join(" ")}
                                                style={styles}
                                                onClick={() => setActiveIndex(i)}
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1 }}
                                                transition={{ delay: 0.05 * i }}
                                            >
                                                <img src={img} alt="IMG" className="Image" />
                                            </motion.div>
                                        );
                                    })}
                                </FanollyImages>
                            </Col>
                            <Col md={6} lg={6}>
                                <motion.div
                                    variants={eachCardVariants}
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true, amount: 0.1 }}
                                >
                                    <AboutThisProjectContainer>
                                        <h3>{titles[activeIndex]}</h3>
                                        {textContents[activeIndex].map((text, i) => {
                                            return (
                                                <p key={i} className="Text">
                                                    {text}
                                                </p>
                                            );
                                        })}
                                    </AboutThisProjectContainer>
                                </motion.div>
                                <motion.div
                                    variants={eachCardVariants}
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true, amount: 0.1 }}
                                >
                                    <ContactButtonsContainer>
                                        <Link to={contents[activeIndex].visitLink} target="_blank">
                                            <VisitWebsiteButton isdotbtn={+true}>
                                                <span>Visit Website</span>
                                            </VisitWebsiteButton>
                                        </Link>

                                        <a href={`mailto:${contents[activeIndex].email}`}>{contents[activeIndex].email}</a>
                                    </ContactButtonsContainer>
                                </motion.div>
                            </Col>
                        </Row>
                    </Container>
                </FeaturesContainer>
            </motion.div>
        </motion.div>
    );
};

export default FanollyPageContent;
