import styled from "styled-components";

export const TabsComponent = styled.div`
    width: 100%;
    margin: auto;
    padding: 40px;

    @media only screen and (max-width: 992px) {
        padding: 40px 15px;
    }
    @media only screen and (max-width: 767px) {
        padding: 20px 0px;
    }

    .tab-links {
        padding: 0;
        margin: 0 auto 20px;
        list-style: none;
        /* max-width: 600px; */
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 15px;
        position: relative;
        z-index: 10;

        @media only screen and (max-width: 1400px) {
            overflow: auto;
            justify-content: flex-start;
        }
        @media only screen and (max-width: 992px) {
            padding: 60px 20px 0px;
        }
    }

    .tab {
        position: relative;
        z-index: 10;
    }
    .tab-content {
        position: relative;
    }

    .tab a {
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.5s ease;
        padding: 16px;
        display: flex;
        align-items: center;

        overflow: hidden;
        position: relative;

        &:hover {
            opacity: 1;
        }
    }

    .tab::before {
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0.7;
        position: absolute;
        border-radius: var(--border-radius);
        background: none;
        transition: background 0.5s ease;
    }

    .tab.active::before {
        background: var(--bg-active-color);
    }

    .tab span {
        font-weight: 700;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.5s ease;
        font-style: normal;
        font-weight: 700;
        font-size: clamp(22px, 6vw, 30px);
        line-height: 50px;
        text-transform: uppercase;
        color: #eeeeee;

        @media only screen and (max-width: 767px) {
            line-height: 1.5;
        }
    }

    .tab.active span {
        color: var(--active-color);
    }

    .tab a {
    }
`;
