import styled from "styled-components";

export const ProjectPageContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    /* background-color: #15161a; */
    position: relative;
    color: #fff;

    .footer {
        background: transparent !important;
    }
`;

export const IconButton = styled.button`
    background: #202021;
    border: 1px solid #585859;
    color: #ffffff;
    font-family: "Helvetica Neue LT Std";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    border-radius: 16px;
    filter: drop-shadow(0px 21px 35px rgba(0, 0, 0, 0.13));
    padding: 10px 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 24px;
        height: 24px;
        path {
            stroke: #ffffff;
        }
    }
`;

export const StickyText = styled.div`
    position: fixed;
    bottom: 20px;
    left: 0;
    z-index: 5;
    cursor: pointer;

    .ScrollInfo {
        &_Contents {
            width: 270px;
            color: #ffffff;
        }
        &_Text {
            color: #ffffff;
        }
        &_Line {
            color: #ffffff;
            background-color: #ffffff;
        }
    }
`;
