import React, { useEffect, useState } from "react";
import { GradientBtn, GradientDotBtn, GradientDotBox } from "./gradientBtnStyle";
import ReactCursorPosition from "react-cursor-position";

const GradientButton = (props) => {
    const btnType = props.isdotbtn;

    return (
        <>
            {btnType ? (
                <ReactCursorPosition className={`mouseContainerBox buttonBox ${props.mpClassName}`}>
                    <MouseDotBox btnProps={props} />
                </ReactCursorPosition>
            ) : (
                <GradientBtn {...props}>{props.children}</GradientBtn>
            )}
        </>
    );
};

const MouseDotBox = (props) => {
    const [mousePos, setMousePos] = useState([0, 0]);
    const { position: { x = 0, y = 0 } = {} } = props;

    useEffect(() => {
        let moveTimeout = true;
        if (moveTimeout) {
            setMousePos([x, y]);
            moveTimeout = false;
            setTimeout(() => (moveTimeout = true), 100);
        }
    }, [x, y]);

    return (
        <>
            {props.btnProps.isBox ? (
                <GradientDotBox posx={mousePos[0]} posy={mousePos[1]} className={`dotBtnBox`} {...props.btnProps}>
                    <div className="btntext">{props.btnProps.children}</div>
                    <span className="rect40"></span>
                    <span className="ellip7"></span>
                </GradientDotBox>
            ) : (
                <GradientDotBtn posx={mousePos[0]} posy={mousePos[1]} className={`dotBtnBox`} {...props.btnProps}>
                    <div className="btntext">{props.btnProps.children}</div>
                    <span className="ellip7"></span>
                    <span className="rect40"></span>
                </GradientDotBtn>
            )}
        </>
    );
};

export default GradientButton;
