import React from "react";
import { motion } from "framer-motion";

const tabContentVariant = {
    active: {
        display: "block",
        transition: {
            staggerChildren: 0.2
        }
    },
    inactive: {
        display: "none"
    }
};

const TabContent = ({ id, active, children }) => (
    <motion.div
        role="tabpanel"
        id={id}
        className="tab-content"
        variants={tabContentVariant}
        animate={active ? "active" : "inactive"}
        initial="inactive"
    >
        {children}
    </motion.div>
);

export default TabContent;
