import { Col, Container, Image, Row } from "react-bootstrap";
import { Images } from "../../../config/images";
import { AboutThisProjectContainer, ContactButtonsContainer, FeaturesContainer, ProjectTitleContainer, VisitWebsiteButton } from "./styles";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";

const CheesiePageContent = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cardVariant = {
        active: {
            opacity: 1,
            y: 0,
            transition: {
                staggerChildren: 0.5,
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        },
        inactive: {
            opacity: 0,
            y: 10,
            transition: {
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        }
    };

    const eachCardVariants = {
        offscreen: {
            y: 10
        },
        onscreen: {
            y: 0,
            //rotate: -10,
            transition: {
                type: "spring",
                // bounce: 0.3,
                duration: 0.4
            }
        }
    };

    const viewPortData = {
        once: true,
        amount: 0.1
    };

    return (
        <motion.div variants={cardVariant}>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <Container>
                    <ProjectTitleContainer>
                        <img src={Images.cheesieLogo} alt="project-logo" />
                        <p>Cheesie</p>
                    </ProjectTitleContainer>
                </Container>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <FeaturesContainer>
                    <Container>
                        <Row>
                            <Col md={12} lg={12}>
                                <Image src={Images.cheeseBanner} alt="project" fluid />
                            </Col>
                        </Row>
                    </Container>
                </FeaturesContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <AboutThisProjectContainer>
                    <Container>
                        <h3>About this project</h3>
                        <p>
                            Cheesie, an innovative cheese production company based in Hyderabad, India, caters specifically to the
                            discerning South Indian market. Cheesie made its highly anticipated debut in November 2023. As a dedicated team
                            of ten talented individuals, we have successfully secured partnerships with leading retail giants in the South
                            Indian market. Moreover, we extend our reach globally by offering worldwide delivery through our efficient
                            online e-commerce platform.
                        </p>
                        <p>
                            Our expertise lies in crafting exceptional nacho cheese sauce that seamlessly blends the timeless American
                            traditional taste with the vibrancy of Southern Indian flavors. Aptly named the "Original Flavor," our sauce
                            incorporates the delightful Peri peri spice mix, infusing a hint of tantalizing spice into the authentic
                            American experience. For more information about our exquisite range of products, please visit our website at{" "}
                            <Link to="https://cheesie.in/">www.cheesie.in</Link>.
                        </p>
                    </Container>
                </AboutThisProjectContainer>
            </motion.div>
            <motion.div variants={eachCardVariants} initial="offscreen" whileInView="onscreen" viewport={viewPortData}>
                <Container>
                    <ContactButtonsContainer>
                        <Link to="https://cheesie.in/">
                            <VisitWebsiteButton isdotbtn={+true}>
                                <span>Visit Website</span>
                            </VisitWebsiteButton>
                        </Link>
                    </ContactButtonsContainer>
                </Container>
            </motion.div>
        </motion.div>
    );
};

export default CheesiePageContent;
