import Marquee from "react-fast-marquee";
import { Images } from "../../config/images";
import { Image } from "react-bootstrap";

const LinerBackground = (props) => {
    return (
        <Marquee {...props} className="linerBg" direction="right" speed={100}>
            <div className="linerImgBlock">
                <Image src={Images.darkLinerBgImg} />
                <Image src={Images.darkLinerBgImg} />
                <Image src={Images.darkLinerBgImg} />
                <Image src={Images.darkLinerBgImg} />
            </div>
        </Marquee>
    );
};

export default LinerBackground;
