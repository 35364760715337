import React, { useEffect, useState } from "react";
import { Container, Navbar, Image, Offcanvas, CloseButton } from "react-bootstrap";
import { ContentBox, HeaderSection, MenuButton, ResumeButton, RightSlideBar } from "./headerStyles";

//images
import { SVGImages, Images } from "../../config/images";
import { Link } from "react-router-dom";
import SocialMedia from "../SocialMedia";

const Header = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
    }, []);

    return (
        <HeaderSection className={`header ${scroll ? "sticky" : ""}`}>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <Image src={Images.akashfaceImg} className="siteLogo" fluid />
                    </Navbar.Brand>
                    <div className="rightMenuBar">
                        <MenuButton variant="primary" onClick={handleShow}>
                            <span></span>
                            <span className="centerLine"></span>
                            <span></span>
                        </MenuButton>
                    </div>

                    <RightSlideBar show={show} onHide={handleClose} placement={"end"}>
                        <Offcanvas.Header>
                            <ResumeButton isdotbtn={+true}>
                                <span>View My Resume</span>
                            </ResumeButton>
                            <CloseButton onClick={handleClose}>
                                <SVGImages.CloseCircleIcon />
                            </CloseButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <ContentBox>
                                <h3>Skills & Experience</h3>
                                <Link to={"/projects"}>Entrepeneur</Link>
                                <Link to={"/film-direction"}>Film Making</Link>
                                <Link to={"/photography"}>Photography</Link>
                            </ContentBox>
                            <ContentBox className="mb-0">
                                <h3>Contact</h3>

                                <SocialMedia />
                            </ContentBox>
                        </Offcanvas.Body>
                    </RightSlideBar>
                </Container>
            </Navbar>
        </HeaderSection>
    );
};

export default Header;
