import styled from "styled-components";
import { Images } from "../../config/images";

export const FooterBar = styled.footer`
    background-color: #ffffff;
    background-image: url("${Images.lightLinerImg}");
    background-position: center;
    background-size: cover;
    /* background-attachment: fixed; */
    padding: 15px;

    &.dark {
        background-color: #15161a;
    }
    &.transparent {
        background-color: transparent;
    }

    &.hideBg {
        background-image: none !important;
    }
`;
export const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 25px;
        color: #303030;
        margin-bottom: 0px !important;
    }

    &.dark {
        p {
            color: #ffffff;
        }
    }

    @media only screen and (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }
`;
