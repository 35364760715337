import { Container } from "react-bootstrap";
import styled from "styled-components";
import GradientButton from "../../../components/GradientButton";

export const PhotoGraphyContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: 100vh;
`;

export const PhotoGraphyContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    padding: 0 0px 5em;
    width: 100%;
    height: 100%;
    right: 0;
    display: inline-flex;
    background-color: #15161a50;

    @media only screen and (max-width: 767px) {
        padding: 0 0px 1em;
    }
`;
export const PhotoGraphyBGImge = styled.div`
    position: relative;
    z-index: 1;
    height: 100%;
    min-height: 100%;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background-image: linear-gradient(to bottom, #15161a, #15161a00);
        z-index: 3;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 25%;
        background-image: linear-gradient(to top, #15161a, #15161a00);
        z-index: 3;
    }

    .marquee-container {
        height: 100%;
        flex-direction: column !important;
        z-index: 2;
        overflow: hidden;
        padding: 0 15px;

        .initial-child-container,
        .child {
            width: 100%;
        }

        .marquee {
            animation-name: marqueeTop !important;

            @keyframes marqueeTop {
                0% {
                    transform: translateY(-100%);
                }
                100% {
                    transform: translateY(0%);
                }
            }
        }
    }
`;

export const PortfolioContent = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    gap: 20px;

    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    h3 {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: clamp(24px, 5vw, 40px);
        line-height: 50px;
        color: #ffffff;
        margin-bottom: 24px;
        @media only screen and (max-width: 800px) {
            line-height: 1.5;
            margin-bottom: 16px;
        }
        @media only screen and (max-width: 767px) {
            text-align: center;
            margin-bottom: 16px;
        }
    }

    p {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
            margin-bottom: 16px;
            text-align: center;
        }
    }

    button {
        transform-origin: center center;
        @media only screen and (max-width: 767px) {
            margin-bottom: 24px;
            text-align: center;
        }
    }
`;

export const ViewPortfolioBtn = styled(GradientButton)`
    span {
        justify-content: center !important;
    }
`;
