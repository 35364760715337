import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import TabContent from "./TabContent";
import { TabsComponent } from "./projectTabCompStyle";

const tabVariant = {
    active: {
        // width: "55%",
        transition: {
            type: "tween",
            duration: 0.4
        }
    },
    inactive: {
        // width: "15%",
        transition: {
            type: "tween",
            duration: 0.4
        }
    }
};

const tabTextVariant = {
    active: {
        // opacity: 1,
        // x: 0,
        display: "block",
        transition: {
            type: "tween",
            duration: 0.3,
            delay: 0.3
        }
    },
    inactive: {
        // opacity: 0,
        //x: -30,
        transition: {
            type: "tween",
            duration: 0.3,
            delay: 0.1
        }
        //transitionEnd: { display: "none" }
    }
};

const ProjectTabComp = ({ tabs, defaultIndex = 0 }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(defaultIndex);

    useEffect(() => {
        document.documentElement.style.setProperty("--active-color", tabs[activeTabIndex].color);
        document.documentElement.style.setProperty("--bg-active-color", tabs[activeTabIndex].bgcolor);
    }, [activeTabIndex, tabs]);

    // Default to a tab based on the URL hash value
    useEffect(() => {
        const tabFromHash = tabs.findIndex((tab) => `#${tab.id}` === window.location.hash);
        setActiveTabIndex(tabFromHash !== -1 ? tabFromHash : defaultIndex);
    }, [tabs, defaultIndex]);

    const onTabClick = (index) => {
        setActiveTabIndex(index);
    };

    return (
        <TabsComponent>
            <ul className="tab-links" role="tablist">
                {tabs.map((tab, index) => (
                    <motion.li
                        key={tab.id}
                        className={`tab ${activeTabIndex === index ? "active" : "inactive"}`}
                        role="presentation"
                        variants={tabVariant}
                        animate={activeTabIndex === index ? "active" : "inactive"}
                    >
                        <a className="fontDrukWide" href={`#${tab.id}`} onClick={() => onTabClick(index)}>
                            <motion.span variants={tabTextVariant}>{tab.title}</motion.span>
                        </a>
                    </motion.li>
                ))}
            </ul>
            {tabs.map((tab, index) => (
                <TabContent key={tab.id} id={`${tab.id}-content`} active={activeTabIndex === index}>
                    <tab.content projectChangeHandler={onTabClick} />
                </TabContent>
            ))}
        </TabsComponent>
    );
};

export default ProjectTabComp;
