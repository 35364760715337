import styled from "styled-components";

export const VimeoWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 3;
    overflow: hidden;
    border-radius: 16px;

    /* &.vimeo-hover-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        pointer-events: none;
        overflow: hidden;
    }
    &.vimeo-hover-wrapper iframe {
        width: 100vw !important;
        height: 56.25vw !important; // Given a 16:9 aspect ratio, 9/16*100 = 56.25
        min-height: 100vh;
        min-width: 177.77vh; // Given a 16:9 aspect ratio, 16/9*100 = 177.77
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } */

    .overlayBox {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        top: 0;
        left: 0;
        &.show {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
        &.hide {
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
        }
    }

    .videoBox {
        position: relative;
        z-index: 1;
        &.show {
            transition: opacity 0.3s ease-in-out;
            opacity: 1;
        }
        &.hide {
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
        }
    }
`;

export const PausedOverlayBox = styled.div`
    overflow: hidden;
    border-radius: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;

    &.show {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
    }
    &.hide {
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
    }

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        border-radius: 16px;
    }

    div {
        /* Ensure the description text is displayed on top of the thumbnail image */
        z-index: 1;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;

        svg {
            width: 50px;
            height: 50px;
        }
    }
`;
export const LoadingOverlayBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    /* Loading overlay covers the player */
    width: 100%;
    height: 100%;
    /* Loading overlay has semi-transparent background so the player
          will be darkened while it's visible */
    background-color: rgba(0, 0, 0, 0.7);
    /* Center the spinner inside of the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &.show {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
    }

    &.hide {
        transition: opacity 0.3s ease-in-out;
        opacity: 0;
    }

    .overlay {
        width: 4em;
        height: 4em;
        border: 6px solid white;
        border-radius: 50%;
        border-color: white white transparent transparent;
        animation: loadingOverlaySpinnerAnimation 1s linear infinite;
    }

    @keyframes loadingOverlaySpinnerAnimation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
