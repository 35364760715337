import { Button } from "react-bootstrap";
import styled from "styled-components";

export const ShadowBtn = styled(Button)`
    background: transparent;
    border-radius: 16px;
    width: auto;
    min-width: 200px;
    height: 50px;
    -webkit-box-shadow: 2px 4px 20px -2px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 4px 20px -2px rgba(0, 0, 0, 0.15);
    -webkit-transition: -webkit-box-shadow 0.4s ease;
    transition: -webkit-box-shadow 0.4s ease;
    -o-transition: box-shadow 0.4s ease;
    transition: box-shadow 0.4s ease;
    transition: box-shadow 0.4s ease, -webkit-box-shadow 0.4s ease;

    &.btn-light {
        background-color: #ffffff;
    }
    &.btn-dark {
        background-color: #15161a;
        span {
            color: #ffffff;
        }
    }

    span {
        font-family: "Helvetica Neue LT Std";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #303030;
    }

    &:hover,
    &:active,
    &:focus {
        background: #ffffff !important;
        /* filter: drop-shadow(0px 21px 35px rgba(0, 0, 0, 0.13)); */
        -webkit-box-shadow: 2px 8px 20px -4px rgba(0, 0, 0, 0.25) !important;
        box-shadow: 2px 8px 20px -4px rgba(0, 0, 0, 0.25) !important;
        border-color: #ffffff !important;
    }

    &:active {
        background: #f6f6f6 !important;
    }

    &.btn-dark {
        &:hover {
            span {
                color: #303030 !important;
            }
        }
    }
`;
