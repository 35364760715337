import React from "react";
import { SVGImages } from "../../config/images";
import styled from "styled-components";

const SocialMediaContainer = styled.div`
    display: flex;
    justify-content: ${(props) => (props.flexDirection === "row" ? "flex-end" : "center")};
    flex-direction: ${(props) => props.flexDirection};
    align-items: ${(props) => (props.flexDirection === "row" ? "flex-end" : "flex-start")};
    gap: 10px;

    span {
        margin-left: 10px;
        font-family: "Helvetica";
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 0;

        @media only screen and (max-width: 767px) {
            font-size: 20px;
        }
    }

    a {
        margin-bottom: 0;
        opacity: 1 !important;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 32px;
            height: 32px;
        }

        /* @media only screen and (max-width: 767px) {
            width: 20px;
            height: 20px;
        } */
    }
`;

const SocialMedia = ({ variant = "dark", inFooter = false }) => {
    return (
        <SocialMediaContainer flexDirection={inFooter ? "row" : "column"}>
            <a href="https://www.instagram.com/iamakashkash/">
                {variant === "dark" ? (
                    <SVGImages.InstagramLightLogo width={30} height={30} />
                ) : (
                    <SVGImages.InstagramDarkLogo width={30} height={30} />
                )}
                {!inFooter && <span>/ iamakashkash</span>}
            </a>
            <a href="https://www.linkedin.com/in/iamakashsekar/">
                {variant === "dark" ? (
                    <SVGImages.LinkedinLightLogo width={35} height={35} />
                ) : (
                    <SVGImages.LinkedInDarkLogo width={35} height={35} />
                )}
                {!inFooter && <span>/ iamakashsekar</span>}
            </a>
        </SocialMediaContainer>
    );
};

export default SocialMedia;
