import styled from "styled-components";

export const ProjectContainer = styled.div`
    overflow: hidden;
    height: 100vh;
    background-color: #15161a;
    background-position: center center;
    transition: all 0.1s ease;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: normal;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(21, 22, 26, 0);
        z-index: 1;
        /* backdrop-filter: blur(14px); */
    }

    @media only screen and (max-width: 767px) {
        min-height: unset;
        height: auto;
    }
`;

export const ProjectContent = styled.div`
    position: relative;
    z-index: 2;
    padding: 60px 0;
`;
export const ProjectItem = styled.div`
    position: relative;
    margin-bottom: 32px;

    .innerContent {
        width: 100%;
        max-width: 480px !important;
        cursor: pointer;
    }

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: clamp(22px, 6vw, 48px);
        line-height: 50px;
        text-transform: uppercase;
        color: #eeeeee;

        @media only screen and (max-width: 767px) {
            line-height: 1.5;
        }
    }

    p {
        height: 0;
        opacity: 0;
        transition: height 0.3s ease-in-out;
        font-size: 18px;
        line-height: 1.2;
    }

    &::before {
        content: "";
        width: 14px;
        height: 87px;
        background: ${(props) => props.projectcolor};
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
        opacity: 0;

        @media only screen and (max-width: 576px) {
            display: none;
        }
    }

    &.active {
        &::before {
            opacity: 1;
            transition: all 0.1s ease-in-out;
        }

        h2 {
            color: ${(props) => props.projectcolor} !important;
        }
        p {
            opacity: 1;
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 1.2;
            color: #ffffff;
            height: 80px;
            transition: height 0.3s ease-in-out;

            @media only screen and (max-width: 767px) {
                height: auto !important;
            }
        }
    }
`;

export const ProjectBGContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    & > * {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.4s ease;

        &.active {
            opacity: 1;
        }
    }
    .snaplarkBg {
        margin: 0;
    }
    .fanollyBg {
        background-color: #010101;
    }
    .sumofameBg {
        // background-color: #02ade6;
        background-color: #ffffff;
    }

    @keyframes cloudsMove {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-200%);
        }
    }
    @keyframes arrowsMove {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-200%);
        }
    }
`;

export const ProjectBgImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    background-position: center center;
    background-image: linear-gradient(rgba(21, 22, 26, 0.3), rgba(21, 22, 26, 0.3)),
        ${(props) => (props.projectbgimg !== "" ? `url("${props.projectbgimg}")` : "")};
`;
