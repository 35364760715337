import "./App.scss";

import { Routes, Route, useLocation } from "react-router-dom";

import Header from "./components/Header";
import Home from "./pages/Home";
import FilmDirection from "./pages/FilmDirection";
import Photography from "./pages/Photography";
import Projects from "./pages/Projects";
import { useEffect } from "react";

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <div className="mainContainer">{children}</div>
        </>
    );
};

function App() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="App">
            <Routes>
                <Route
                    path="/"
                    element={
                        <Layout>
                            <Home />
                        </Layout>
                    }
                />

                <Route exact path="/film-direction/" element={<FilmDirection />} />
                <Route path="/photography" element={<Photography />} />
                <Route path="/projects" element={<Projects />} />
            </Routes>
        </div>
    );
}

export default App;
