import styled from "styled-components";
import { Images } from "../../../config/images";

export const BannerWrapper = styled.div`
    overflow: hidden;
    height: 100vh;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: normal;

    padding: 80px 0 0;

    @media only screen and (max-width: 767px) {
        min-height: unset;
        height: auto;
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        position: absolute;
        z-index: 9;
        background: linear-gradient(to bottom, #ffffff00 60%, #15161a);
    }

    .marquee-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        flex-direction: column !important;
        z-index: 3;
        opacity: 0.3;

        .marquee {
            background-image: url("${Images.darkLinerBgImg}");
            background-position: center;
            background-size: contain;
            animation-name: marqueeTop !important;
            background-attachment: fixed;

            @keyframes marqueeTop {
                0% {
                    transform: translateY(-100%);
                }
                100% {
                    transform: translateY(0%);
                }
            }
        }

        img {
            max-width: 1200px;
            width: 100%;
            opacity: 0;
        }
    }
`;
export const TextContainer = styled.div`
    max-width: 447px;
    width: 100%;
    margin: 15px auto 15px;
    text-align: center;
    position: relative;
    z-index: 11;

    @media only screen and (max-width: 992px) {
        padding: 0 15px;
    }

    h2 {
        font-family: "Helvetica Neue LT Std";
        font-style: normal;
        font-weight: 500;
        font-size: clamp(23px, 4vw, 30px);
        line-height: 1;

        @media only screen and (max-width: 992px) {
            margin-bottom: 15px;
        }
    }
    h3 {
        font-family: "Helvetica Neue LT Std";
        font-style: normal;
        font-weight: 700;
        font-size: clamp(28px, 4vw, 32px);
        line-height: 1;
        position: relative;
        padding-bottom: 10px;
        overflow: hidden;

        &::after {
            content: "";
            width: calc(100% - 0px);
            margin: auto;
            right: 0;
            height: 5px;
            bottom: 0;
            left: 0;
            position: absolute;
            background-image: linear-gradient(29deg, rgba(79, 79, 81, 0), rgba(159, 159, 159, 1));
            border-radius: 16px;
            animation: spins 3s ease 1 forwards;
            animation-delay: 2s;
            opacity: 0;

            @keyframes spins {
                0% {
                    opacity: 1;
                    transform: translateX(-100%);
                }
                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }

            @media only screen and (max-width: 992px) {
                display: none;
            }
        }
    }
`;

export const DropDownContainer = styled.div`
    max-width: 900px;
    width: 100%;
    margin: 10px auto 10px;
    transform: translateY(15px);
    position: relative;
    z-index: 13;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 39px;

    @media only screen and (max-width: 767px) {
        gap: 20px;
        transform-origin: center center;
    }

    .dropdown {
        .ddBtnContent {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            svg {
                transition: all 0.1s ease-in-out;
                transform: rotate(0deg);
            }
        }

        &.show {
            .ddBtnContent {
                svg {
                    transition: all 0.1s ease-in-out;
                    transform: rotate(180deg);
                }
            }
        }
    }
`;

export const BannerSlider = styled.div`
    /* transform: translateY(10px); */
    position: relative;
    z-index: 12;
    overflow: hidden;

    .slick-slider {
        .slick-list {
            padding: 50px 25% 0 !important;

            @media only screen and (max-width: 1024px) {
                padding: 50px 15% 0 !important;
            }
            @media only screen and (max-width: 600px) {
                padding: 50px 5% 0 !important;
            }
        }
        .slick-track {
            display: flex !important;
            height: 100%;

            &::after,
            &::before {
                display: none !important;
            }

            .slick-slide {
                display: flex !important;
                /* height: auto !important; */
                height: 100% !important;
                align-items: flex-end !important;
                justify-content: center !important;

                > div {
                    display: flex;
                    align-items: flex-end !important;
                    justify-content: center !important;
                    height: 100% !important;
                }

                .image-slide {
                    margin-top: 100px;
                    height: 560px !important;
                    /* overflow: hidden !important; */
                    position: relative;
                    transition: all 0.3s ease-in-out;

                    @media only screen and (min-width: 1600px) {
                        height: 712px !important;
                        margin-top: 200px;
                    }

                    @media only screen and (min-width: 1900px) {
                        height: 812px !important;
                    }
                    @media only screen and (max-width: 767px) {
                        height: 280px !important;
                        margin-top: 50px;
                    }
                    @media only screen and (max-width: 480px) {
                        height: 250px !important;
                        margin-top: 30px;
                    }

                    .slideContainer {
                        display: block;
                        width: calc(100% - 30px);
                        margin: auto;
                        position: relative;
                        height: 100%;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 100%;
                            background-image: linear-gradient(to bottom, #ffffff00 0%, #15161a 85%);
                            position: absolute;
                            top: 6px;
                            left: 0;
                        }
                    }

                    img {
                        display: block;
                        width: 100%;
                        margin: auto;
                    }
                }

                .projectLink {
                    transform: scale(0);
                    opacity: 0;
                    transform-origin: center center;
                    transition: all 0.3s ease-in-out;
                    &.wrapper {
                        position: absolute;
                        top: -45px;
                        right: -35px;
                        z-index: 10;
                        width: 80px;
                        height: 80px;
                        margin: 0px auto;
                        border-radius: 100px;

                        background: #242424;
                        background: linear-gradient(#242424, #242424);
                        /* position: relative; */
                        cursor: pointer;
                        box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.13), 0 5px 8px rgba(0, 0, 0, 0.5),
                            0 10px 10px 4px rgba(0, 0, 0, 0.3);

                        @media only screen and (max-width: 600px) {
                            top: -34px;
                            right: -20px;
                        }
                    }

                    a {
                        &.inner {
                            border-radius: inherit;
                            transition: all 0.3s ease-in-out;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.2s ease-in-out;
                            padding: 12px;

                            .linkIconWrapper {
                                width: 100%;
                                height: 100%;
                                border-radius: inherit;
                                background: linear-gradient(var(--bgPosDeg), #000000 2.77%, #7e7e7e 176.99%);
                                //background: linear-gradient(187.16deg, #000000 2.77%, #7e7e7e 176.99%);
                                box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.05), 0 2px 5px rgba(255, 255, 255, 0.1);
                                transition: all 0.3s ease-in-out;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .sendIcon {
                                    position: relative;
                                    z-index: 11;
                                    width: 35%;
                                    height: 35%;
                                }
                            }

                            &:hover {
                                transition: all 0.2s ease-in-out;
                            }
                        }
                    }
                }

                &.slick-active.slick-center .image-slide {
                    margin-top: 0px !important;
                    transition: all 0.3s ease-in-out;
                    height: calc(560px + 100px) !important;

                    @media only screen and (min-width: 1600px) {
                        height: calc(712px + 200px) !important;
                    }
                    @media only screen and (min-width: 1900px) {
                        height: calc(812px + 200px) !important;
                    }

                    @media only screen and (min-width: 2100px) {
                        height: calc(912px + 200px) !important;
                    }

                    @media only screen and (max-width: 767px) {
                        height: calc(280px + 50px) !important;
                    }

                    @media only screen and (max-width: 480px) {
                        height: calc(250px + 30px) !important;
                    }

                    .projectLink {
                        transform: scale(1);
                        opacity: 1;
                        transform-origin: center center;
                        transition: all 0.3s ease-in-out;

                        @media only screen and (max-width: 767px) {
                            transform: scale(0.7);
                        }
                    }
                }
            }
        }
    }
`;
