export const videoSliderData = [
    {
        videoID: [717039874, "3646456533"]
    },
    {
        videoID: [717039303, "2c0b837f0a"]
    },
    {
        videoID: [638138429, "1700684140"]
    },
    {
        videoID: [546366966, "9c05822a2f"]
    },
    {
        videoID: [546365862, "19a8f2f47d"]
    },
    {
        videoID: [483978638, "2154b0c836"]
    },
    {
        videoID: [458819790, "3c84497bdd"]
    },
    {
        videoID: [458819754, "6c6c1e9aa5"]
    },
    {
        videoID: [458819750, "83bb5a8fca"]
    }
];

export const videoPageData = [
    {
        videoID: [717039874, "3646456533"],
        contentType: "",
        texts: {
            about: [
                "A project done in Delhi, India. Little tough to on the exposure control due to a extreme sunny day. Post production was done to replace skies and color correct with exposure controls too. Pre production in 48 hours and shoot was done in less than 2 hours. Thanks to Kiran for the sponsor to strive though a professional outcome. Good clients lead to a good project~!"
            ],
            casts: [
                "Direction and Camera: Akash Sekar",
                "Outfit: The Traditional Red",
                "Jewellery: Odhna",
                "Model: Mandeep Kaur",
                "Makeup: Rahul Kataria and Rohit Madaan",
                "Hair: Shikhar Verma"
            ]
        },
        nextVideolink: "512004064"
    },
    {
        videoID: [717039303, "2c0b837f0a"],
        contentType: "",
        texts: {
            about: [
                "A project done during my film making college days. Special thanks to Solomon Raj to share his story to the world... About the project; Solomon Raj — often referred to as ‘Appa’, is giving a home and bringing smiles to 47 HIV+ children through his NGO, shelter trust in Chennai, India. Watch the full documentary on YouTube"
            ],
            casts: [
                "Directed by: Akash Sekar",
                "Cinematography: Shivv Parkash, Subash Chandra and Bhargav Chakravarthy",
                "Editor: Soham Bhowmik",
                "Sound design: Hrishikesh Vasishth",
                "Subtitle: Nalan Chakaravarthy"
            ]
        },
        nextVideolink: "638138429"
    },
    {
        videoID: [638138429, "1700684140"],
        contentType: "",
        texts: {
            about: [
                `A re-do of Mahabalipuram's part 2 shoot. Most likely a revised version as the previous version was more of the client's standard. Post-production was completed during the Covid-19 pandemic period. Made well use of my time I believe. Please do check out Part 1's shoot and video. You'll see a massive improvement :)`
            ],
            casts: ["By: Akash Sekar", "Model: Jane Thompson", "Stylist: Pavitra Shankar Prasad", "Spot asst: MD Khan"]
        },
        nextVideolink: "546366966"
    },
    {
        videoID: [546366966, "9c05822a2f"],
        contentType: "",
        texts: {
            about: [
                `A dreamy mermaid with a gorgeous body shape to fit the viewer's dream of how an actual mermaid would ever look like. The ambience light with the continuous light and flash brought the shoot's dreamy mood as I expected. Being a Set decor and prop artist, I found this challenging to handle all by myself. Kim Loo's professional modelling skills helped me balance the shoot well, and we wrapped the shoot in under 2 hours.`
            ],
            casts: ["By: Akash Sekar", "Model: Kim Loo", "Thanks: Rb Torres for the lighting equipments"]
        },
        nextVideolink: "546365862"
    },
    {
        videoID: [546365862, "19a8f2f47d"],
        contentType: "",
        texts: {
            about: [
                `A second project with Daisy. Collaboration with Lounge underwear & apparel. Daisy's sexy and natural body breaks the stereotype of a dream body/shape that a viewer might expect from a genre like this. I've excluded filtering and customizing through photoshop to fit the 'dream' body shape. Let this be the most natural photoshoot in my list of album ;)`
            ],
            casts: ["By: Akash Sekar", "Model: Daisy", " ... ", "Just us and no one else!"]
        },
        nextVideolink: "483978638"
    },
    {
        videoID: [483978638, "2154b0c836"],
        contentType: "",
        texts: {
            about: [
                "The shoot was held in Singapore @ Lakeside gardens in the very early sunrise, with beautiful Veronica. Without the lens hood assist, I was able to pull and compose shots that hit the lens undeviatingly, which produced the glorious flare. Shot and directed in less than 1 hour with 2 Styles. Check out the photoshoot for the complete album."
            ],
            casts: ["By: Akash Sekar", "Model: Veronica Shaw", " ... ", "Just us and no one else!"]
        },
        nextVideolink: "458819790"
    },
    {
        videoID: [458819790, "3c84497bdd"],
        contentType: "",
        texts: {
            about: [
                "Featuring the stunning Simran Pareek, in the golden hours of Chennai's seashore, Waves hitting on rocks. Simran's on @SheinOfficial bikini with a custom headpiece designed by Harshitha Pragathi. Both styles were shot and directed within 2 hours, including the styling and makeup time."
            ],
            casts: [
                "By: Akash Sekar",
                "Model: Simran Pareek",
                "MUAH: Anupama Krishnamachari",
                "Stylist: Samiha Iyer",
                "Assistant: Harshitha Pragathi"
            ]
        },
        nextVideolink: "458819754"
    },
    {
        videoID: [458819754, "6c6c1e9aa5"],
        contentType: "",
        texts: {
            about: [
                "Magnificent location, Mahabalipuram is best known for the UNESCO World Heritage Site. Shot with the rise of the sun. Beautiful Jane Thompson with Indian traditional lehenga, custom made by a designer based in Chennai. The team Hiked up to the tallest mountains in Mahabalipuram for a fantastic shot like this. Check out the photoshoot album for more! "
            ],
            casts: [
                "By: Akash Sekar",
                "Model: Jane Thompson",
                "Stylist: Pavitra Shankar Prasad",
                "Location asst: MD Khan",
                "Costume: Soul Spectrum",
                "Jewellery: Nayaki"
            ]
        },
        nextVideolink: "458819750"
    },
    {
        videoID: [458819750, "83bb5a8fca"],
        contentType: "",
        texts: {
            about: [
                "Vinu Sha's in the beautiful tropical garden. More than 50km away from Chennai. In the very early morning wearing a private, designed black saree with sliver coated pieces of jewellery. With the blooming flowers in hand just and the slow and steady movements in the shots made this shoot a dreamlike sequence. "
            ],
            casts: ["By: Akash Sekar", "Model: Vinu Sha", "Assistant: Subash Chandra"]
        },
        nextVideolink: "717039874"
    },
];
