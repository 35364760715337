import { Images } from "../../../../../config/images";
import { SumofameContainer } from "./sumofameContentStyle";

const clouds = [Images.cloud1, Images.cloud2, Images.cloud3, Images.cloud4, Images.cloud5];
const randClouds = [];
const randClouds2 = [];
for (let i = 0; i < Math.max(1, window.innerWidth / (240 + 450 + 20) + 1); i++) {
    for (let j = 0; j < Math.max(1, window.innerHeight / 350); j++) {
        if (Number.parseInt(Math.random() * 5) === 2) {
            break;
        }
        randClouds.push({
            index: Number.parseInt(Math.random() * clouds.length),
            x: 450 * (i + 1) + 100 * (j % 2) + Math.random() * 20 - 10,
            y: 340 * j + 50 * (i % 2) + Math.random() * 20 - 10
        });
        randClouds2.push({
            index: Number.parseInt(Math.random() * clouds.length),
            x: 550 * (i + 1) + 100 * (j % 2) + Math.random() * 20 - 10,
            y: 350 * j + 50 * (i % 2) + Math.random() * 20 - 10
        });
    }
}
const setRandArrows = () => {
    // let pX=0, pY=0;
    let lY = null;
    const arr = [];
    for (let i = 0; i < Math.max(20, window.innerWidth / 70 + 1); i++) {
        if (lY !== null) {
            if (lY > 300 && lY < window.innerHeight - 250) {
                const y1 = Math.random() * (lY - 250) + 25;
                const y2 = Math.random() * (lY + 250) - 25 + window.innerHeight / 2;
                lY = Math.random() * 2 < 1 ? y1 : y2;
            } else if (lY > 300) {
                lY = Math.random() * (lY - 250) + 25;
            } else {
                lY = Math.random() * (lY + 250) - 25 + window.innerHeight / 2;
            }
        } else {
            lY = Math.random() * (window.innerHeight - 50) + 25;
        }
        arr.push({ x: (240 + 100) * i, y: lY, fire: Math.random() * 2 < 1 });
    }
    return arr;
};

const arrowArr = setRandArrows();

const SumofameContent = () => {
    return (
        <SumofameContainer>
            <div className="Black"></div>
            <div className="Clouds">
                <div className="Clouds1">
                    {randClouds.map((cloud, i) => (
                        <img
                            src={clouds[cloud.index]}
                            key={i}
                            alt="C"
                            className="Cloud"
                            style={{
                                left: cloud.x + "px",
                                top: cloud.y + "px"
                            }}
                        />
                    ))}
                </div>
                <div className="Clouds2">
                    {randClouds2.map((cloud, i) => (
                        <img
                            src={clouds[cloud.index]}
                            key={i}
                            alt="C"
                            className="Cloud"
                            style={{
                                left: cloud.x + "px",
                                top: cloud.y + "px"
                            }}
                        />
                    ))}
                </div>
                <div className="Clouds3">
                    {randClouds.map((cloud, i) => (
                        <img
                            src={clouds[cloud.index]}
                            key={i}
                            alt="C"
                            className="Cloud"
                            style={{
                                left: cloud.x + "px",
                                top: cloud.y + "px"
                            }}
                        />
                    ))}
                </div>
                <div className="Clouds4">
                    {randClouds2.map((cloud, i) => (
                        <img
                            src={clouds[cloud.index]}
                            key={i}
                            alt="C"
                            className="Cloud"
                            style={{
                                left: cloud.x + "px",
                                top: cloud.y + "px"
                            }}
                        />
                    ))}
                </div>
            </div>

            <div className="Arrows">
                {arrowArr.map((item, i) => {
                    //const firstX = arrowArr[0].x;
                    const lastX = arrowArr[arrowArr.length - 1].x;
                    const maxX = lastX + item.x;

                    const v = lastX / 8000;
                    const dur = maxX / v;

                    return (
                        <div
                            key={i}
                            className="Arrow"
                            style={{
                                top: item.y + "px",
                                "--x": item.x + "px",
                                "--y": item.y + "px",
                                "--maxX": lastX + 240 + 100 + "px",
                                "--dur": dur + "ms",
                                animationDelay: 1800 * i + "ms"
                            }}
                        >
                            <img src={Images.arrowImg} alt="A" className="ArrowImg" />

                            {item.fire && <img src={Images.fireImg} alt="F" className="Fire" />}
                        </div>
                    );
                })}
            </div>

            <img src={Images.sumofameImg} alt="S" className="MainLogo" />
            <img src={Images.sumoIcon} alt="I" className="SumoIcon" />
            <img src={Images.sumoBg1} alt="BG" className="BottomBg Bg1" />
            <img src={Images.sumoBg2} alt="BG" className="BottomBg Bg2" />
        </SumofameContainer>
    );
};

export default SumofameContent;
