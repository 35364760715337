import styled from "styled-components";
import { Button } from "react-bootstrap";
import { Images } from "../../../config/images";

export const ContactUsContainer = styled.div`
    background: #ffffff;
    position: relative;

    padding: 60px 0;
    overflow: hidden;
    height: 100vh;

    @media only screen and (max-width: 767px) {
        min-height: unset;
        height: auto;
        background-image: url(${Images.lightLinerImg});
        background-position: center;
        background-size: cover;
        background-attachment: fixed;

        .parallax.linerBg {
            display: none !important;
        }
    }

    @media only screen and (max-width: 480px) {
        padding-bottom: 15px;
    }

    @media only screen and (max-width: 600px) {
        background-image: none !important;
    }

    .container {
        height: 100%;
        position: relative;
        z-index: 10;
    }

    .footer {
        background-color: transparent !important;
        z-index: 10;
    }

    .innerContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;
export const HeadingContent = styled.div`
    margin-bottom: 40px;
    max-width: 628px;
    width: 100%;

    @media only screen and (max-width: 992px) {
        margin-left: auto;
        margin-right: auto;
    }

    .innerContent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 27px;

        h3 {
            font-family: "Helvetica";
            font-style: normal;
            font-weight: 700;
            font-size: clamp(26px, 8vw, 66px);
            line-height: 80px;
            color: #303030;
            margin-bottom: 0;

            @media only screen and (max-width: 767px) {
                line-height: 1.5;
            }
        }

        img {
            @media only screen and (max-width: 767px) {
                width: 30px;
            }
        }
    }

    p {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #303030;
        width: 100%;
        max-width: 600px;
    }
`;

export const ShadowButton = styled(Button)`
    background: #ffffff;
    border-radius: 16px;
    width: 254px;
    height: 69px;
    filter: drop-shadow(0px 21px 35px rgba(0, 0, 0, 0.13));
    margin: 0px auto 0 !important;

    span {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #303030;
    }
`;
export const FormContainer = styled.div`
    max-width: 628px;
    width: 100%;

    @media only screen and (max-width: 992px) {
        margin-left: auto;
        margin-right: auto;
    }

    .form-control {
        background-color: #ffffffa1 !important;

        &:focus {
            box-shadow: none !important;
            outline: none !important;
        }
    }

    @media only screen and (max-width: 767px) {
        .row {
            margin-bottom: 0 !important;
        }
        .col {
            flex: unset !important;
            margin-bottom: 0.5rem !important;
        }

        .submitBtn {
            margin: 1rem auto !important;
        }
    }
`;
export const BusinessCardContent = styled.div`
    text-align: center;
    height: 100%;
    display: flex;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    img {
        width: 100%;
        max-width: 350px;
        margin: auto;
    }

    @media only screen and (max-width: 767px) {
        margin: 3em auto;
    }
`;
export const FeaturedInContent = styled.div`
    padding: 20px 0px 20px;

    p {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #303030;
        text-align: center;

        @media only screen and (min-width: 768px) {
            margin-bottom: 0;
        }
    }

    .innerContent {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
        max-width: 576px;
        margin: 10px auto;

        img {
            max-width: 200px;
            width: 100%;
        }

        @media only screen and (max-width: 767px) {
            margin: 2em auto 0.5em;
            flex-wrap: wrap;
            gap: 0.5em;
        }
    }
`;
