import React, { useEffect } from "react";
import { FilmContainer, HeadingContent, FilmSliderSection, FilmSliderBox, FilmContainerMain } from "./filmStyles";
import FilmSlider from "../../../components/FilmSlider";
import { Container } from "react-bootstrap";
import ShadowButton from "../../../components/ShadowButton";
import { useNavigate } from "react-router-dom";
import MFBackground from "../../../components/MFBackground";
import useMousePosition from "../../../hooks/useMouse";
import ConditionalContent from "../../../components/ConditionalContent";

const FilmSection = (props) => {
    return (
        <FilmContainerMain id="film-section" className="filmContainerMain">
            <div className="mouseContainerBox">
                <FilmContentSection {...props} />
            </div>
        </FilmContainerMain>
    );
};

const FilmContentSection = (props) => {
    const navigate = useNavigate();
    const { maskSize, setMaskSize, ratio } = props;
    const maskMinSize = Math.max(window.innerWidth * 0.06 * ratio, 80 * ratio);

    const mouseEnterHandler = () => {
        setMaskSize(window.innerWidth * 1.3);
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");

        scrollTextEle.style.color = "#ffffff";
        scrollLineEle.style.backgroundColor = "#ffffff";
    };
    const mouseLeaveHandler = () => {
        setMaskSize(maskMinSize);
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");

        scrollTextEle.removeAttribute("style");
        scrollLineEle.removeAttribute("style");
    };

    const { x, y } = useMousePosition();

    useEffect(() => {
        const scrollTextEle = document.querySelector(".ScrollInfo_Text");
        const scrollLineEle = document.querySelector(".ScrollInfo_Line");
        scrollTextEle.removeAttribute("style");
        scrollLineEle.removeAttribute("style");
    }, [props.isAcitve]);

    return (
        <>
            <FilmContainer className="original">
                <Container>
                    <HeadingContent>
                        <h2 className="fontHelvetica">
                            <span>Film </span>
                            <br />
                            <span>Direction</span>
                        </h2>
                        <ConditionalContent $maxWidth={768}>
                            <ShadowButton
                                onClick={() =>
                                    navigate("/film-direction", {
                                        state: { id: false }
                                    })
                                }
                            >
                                <span>Click to Discover</span>
                            </ShadowButton>
                        </ConditionalContent>
                    </HeadingContent>
                </Container>
                <FilmSliderSection>
                    {/* <HorizontalText title="Film Making"></HorizontalText> */}
                    <FilmSliderBox className="videoSliderOrg">
                        <FilmSlider />
                    </FilmSliderBox>
                </FilmSliderSection>
                <ConditionalContent $minWidth={767}>
                    <ShadowButton
                        className="mt-4 mx-auto"
                        onClick={() =>
                            navigate("/film-direction", {
                                state: { id: false }
                            })
                        }
                    >
                        <span>Click to Discover</span>
                    </ShadowButton>
                </ConditionalContent>
                <MFBackground />
            </FilmContainer>
            <FilmContainer
                className="maskOverlay"
                style={{
                    clipPath: `circle(${maskSize}px at ${x}px ${y}px)`
                }}
            >
                <Container>
                    <HeadingContent>
                        <h2 className="fontHelvetica">
                            <span>Film </span>
                            <br />
                            <span>Direction</span>
                        </h2>
                        <ShadowButton
                            variant="dark"
                            onClick={() =>
                                navigate("/film-direction", {
                                    state: { id: false }
                                })
                            }
                        >
                            <span>Click to Discover</span>
                        </ShadowButton>
                    </HeadingContent>
                </Container>
                <FilmSliderSection onMouseEnter={() => mouseEnterHandler()} onMouseLeave={() => mouseLeaveHandler()}>
                    {/* <HorizontalText title="Film Making"></HorizontalText> */}
                    <FilmSliderBox className="videoSlider">
                        <FilmSlider />
                    </FilmSliderBox>
                </FilmSliderSection>
                <MFBackground />
            </FilmContainer>
        </>
    );
};

export default FilmSection;
