import styled from "styled-components";
import { Images } from "../../config/images";
import { motion } from "framer-motion";

// import lightLinerImg from "../../assets/images/lightLinerImg@2x.png";

export const FilmDirectionContainer = styled.div`
    background-color: #ffffff;
    background-image: url("${Images.lightLinerImg}");
    background-position: center;
    background-size: contain;
    background-attachment: fixed;
    transition: all 0.3s ease-in-out;
`;

export const FilmDirectionBanner = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #15161a;
    overflow: hidden;

    @media only screen and (max-width: 1280px) {
        height: auto;
    }
`;

export const BannerContent = styled(motion.div)`
    position: absolute;
    width: 100vw;
    /* min-height: 100vh; */
    background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, #ffffff 100%, #ffffff 100%);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bannerContent {
        position: relative;
        z-index: 50;
        height: 100% !important;
        width: 100% !important;
        //background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, #ffffff 100%, #ffffff 100%);
    }
    .headerContent {
        position: relative;
        z-index: 52;
        transition: opacity 0.2s ease-in-out;
        @media (max-width: 767px) {
            padding-top: 15px;
        }

        &.active {
            z-index: -1;
            opacity: 0;
        }
    }
    .bodyContent {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 51;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .iconBtn {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out;
            svg {
                margin: auto auto;
                width: 60px;
                height: 60px;

                @media (max-width: 767px) {
                    transform: scale(0.7);
                }
            }

            &.playIcon {
                &.active {
                    opacity: 0;
                }
            }

            &.fsBtn {
                position: absolute;
                width: 40px;
                height: 40px;
                bottom: 0;
                right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: opacity 0.2s ease-in-out;
                opacity: 0;

                &.active {
                    opacity: 1;
                }
            }
        }
    }
    .bgBannerImage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s ease-in-out;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, transparent 0%, transparent 50%, #15161a 100%, #15161a 100%);
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        &.showOverlay {
            opacity: 1;
            z-index: 3;
        }

        &.hideOverlay {
            opacity: 0;
        }
    }
`;

export const Title = styled.p`
    color: #303030;
    font-family: "Helvetica Neue LT Std";
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 20px;

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

export const AboutFilm = styled.div`
    margin-top: 6em;

    p.description {
        color: #303030;
        font-family: "Helvetica";
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        margin: 0;
        padding: 0;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    @media (max-width: 940px) {
        margin-top: 3em;
        padding: 0 15px;
    }
    @media (max-width: 480px) {
        margin-top: 15px;
        p.description {
            font-size: 16px;
        }
    }
`;

export const CastInfo = styled.div`
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            color: #303030;
            font-family: "Helvetica";

            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
        }
    }

    @media (max-width: 480px) {
        ul {
            li {
                font-size: 16px;
            }
        }
    }
`;

export const OtherProjects = styled.div`
    width: 100%;
    padding: 0;

    margin: 60px 0 25px;

    .sliderContainer {
        padding-left: 100px;

        @media (max-width: 767px) {
            padding-left: 12px;
        }
    }
`;

export const SocialMedia = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 0;

    @media (max-width: 767px) {
        padding: 0 15px;

        button {
            transform: scale(0.7);
        }
    }

    .btn {
        justify-content: space-around;
        min-width: 170px;
        height: 45px;

        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
        }

        svg {
            transform: translateX(-5px);
            margin-left: 5px;
            transition: transform 0.3s ease-in-out;
        }

        &:hover {
            svg {
                transform: translateX(0px);
                margin-left: 5px;
                transition: transform 0.3s ease-in-out;
            }
        }
    }
`;

export const FilmSliderSection = styled.div`
    margin: 60px 0 0px;
    position: relative;
    /* padding-left: 54px;
    height: 350px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    /* @media (max-width: 480px) {
        padding: 0 48px;
    } */

    @media (max-width: 940px) {
        padding: 0 15px;
    }
    @media (max-width: 480px) {
        padding: 0 15px;
    }
`;
export const FilmSliderBox = styled.div`
    width: calc(100% - 50px);
`;
export const StickyText = styled.div`
    position: fixed;
    bottom: 20px;
    cursor: pointer;

    .ScrollInfo_Contents {
        width: 270px;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;
export const FilmContentView = styled.div`
    position: relative;
`;

export const FilmDirectionSection = styled.div`
    &.sliderHover {
        .filmDirectionContainer {
            background-color: #000000 !important;
            transition: all 0.3s ease-in-out;

            * {
                color: #ffffff !important;
                transition: all 0.3s ease-in-out;
            }

            svg {
                filter: brightness(0) invert();
                transition: all 0.3s ease-in-out;
            }

            .btn {
                span {
                    color: #15161a !important;
                }
                svg {
                    filter: brightness(0) invert(0);
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        .slick-slider {
            &::after {
                background-image: linear-gradient(to left, #15161a, #15161a00);
            }
        }
    }
`;
