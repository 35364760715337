import styled from "styled-components";
import { Videos } from "../../../../../config/images";

const VideoContainer = styled.div`
    .video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .black {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }
`;

const SnaplarkContent = () => {
    return (
        <VideoContainer>
            <div className="video">
                <video loop autoPlay muted>
                    <source src={Videos.snaplarkVideo} type="video/mp4" />
                    <source src={Videos.snaplarkVideo} type="video/mp4" />
                </video>
            </div>
            <div className="black"></div>
        </VideoContainer>
    );
};

export default SnaplarkContent;
