import React, { useState } from "react";
import FilmSlider from "../../components/FilmSlider";
import { useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
    AboutFilm,
    ButtonContainer,
    CastInfo,
    FilmDirectionContainer,
    FilmDirectionBanner,
    FilmSliderBox,
    FilmSliderSection,
    OtherProjects,
    Title,
    BannerContent,
    StickyText,
    FilmContentView,
    FilmDirectionSection
} from "./styles";
import { SVGImages } from "../../config/images";
import HorizontalText from "../../components/HorizontalText";
import BackgroundVideo from "../../components/BackgroundVideo";
import { IconButton } from "../Projects/styles";
import { Container, Image } from "react-bootstrap";
import ShadowButton from "../../components/ShadowButton";
import Footer from "../../components/Footer";
import { videoPageData } from "../../utils/videoData";
import ConditionalContent from "../../components/ConditionalContent";

const FilmDirection = () => {
    const params = useLocation();
    let filmData = videoPageData[0];
    let videoSlideIndex = 0;

    if (params.state.id) {
        filmData = videoPageData.find((videoData) => videoData.videoID[0] === Number(params.state.id));
        videoSlideIndex = videoPageData.findIndex((videoData) => videoData.videoID[0] === Number(params.state.id));
    }

    const filmID = filmData.videoID;
    const filmContent = filmData.texts.about;
    const filmCast = filmData.texts.casts;
    const nextLink = filmData.nextVideolink;
    const videoURL = `https://player.vimeo.com/video/${filmID[0]}?h=${filmID[1]}`;
    const videoImg = `https://vumbnail.com/${filmID[0]}_large.jpg`;

    const navigate = useNavigate();
    const [overlay, showOverlay] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);

    const onPlayMedia = () => {
        if (isReady) {
            setPlaying((preValue) => {
                return !preValue;
            });
            showOverlay((preValue) => {
                return !preValue;
            });
        }
    };

    const onEndedHandler = () => {
        setPlaying(false);
        showOverlay(true);
    };
    const onReadyHandler = () => {
        setIsReady(true);
        setPlaying(false);
        showOverlay(true);
    };
    const changepPageHandler = (page) => {
        setPlaying(false);
        showOverlay(true);
        navigate("/film-direction", {
            state: { id: page }
        });
    };
    const enterMouseHandler = () => {
        //const mainElement = document.querySelector(".filmDirectionPage");
        // mainElement.classList.add("sliderHover");
    };
    const outMouseHandler = () => {
        //const mainElement = document.querySelector(".filmDirectionPage");
        // mainElement.classList.remove("sliderHover");
    };

    const animations = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    };

    return (
        <FilmDirectionSection className="filmDirectionPage sliderHover">
            <FilmDirectionContainer className="filmDirectionContainer">
                <FilmDirectionBanner>
                    <BackgroundVideo
                        src={videoURL}
                        muted={false}
                        autoplay={false}
                        loop={true}
                        playing={playing}
                        className={"videoFrame"}
                        onEnded={onEndedHandler}
                        controls={false}
                        onReady={onReadyHandler}
                    />
                    <BannerContent>
                        <div className={`bannerContent`}>
                            <div className={`headerContent ${playing ? "active" : ""}`}>
                                <Container>
                                    <ConditionalContent $maxWidth={767}>
                                        <ButtonContainer>
                                            <ShadowButton variant={"light"} onClick={() => navigate("/")}>
                                                <span>Back to Homepage</span>
                                            </ShadowButton>
                                            <ShadowButton variant={"light"} onClick={() => changepPageHandler(nextLink)}>
                                                <span>Next Project</span>
                                                <SVGImages.NextArrow width={32} height={32} />
                                            </ShadowButton>
                                        </ButtonContainer>
                                    </ConditionalContent>
                                    <ConditionalContent $minWidth={768}>
                                        <ButtonContainer>
                                            <IconButton>
                                                <SVGImages.HomeIcon onClick={() => navigate("/")} />
                                            </IconButton>
                                            <IconButton>
                                                <SVGImages.NextArrowLight onClick={() => changepPageHandler(nextLink)} />
                                            </IconButton>
                                        </ButtonContainer>
                                    </ConditionalContent>
                                </Container>
                            </div>
                            <div className="bodyContent">
                                <div className={`iconBtn playIcon ${playing ? "active" : ""}`} onClick={() => onPlayMedia()}>
                                    {playing ? <SVGImages.PauseCircleFill /> : <SVGImages.PlayCircleFill />}
                                </div>
                            </div>
                        </div>
                        <div className={`bgBannerImage ${overlay ? "showOverlay" : "hideOverlay"}`}>
                            <Image src={videoImg} fluid />
                        </div>
                    </BannerContent>
                </FilmDirectionBanner>

                <FilmContentView>
                    <motion.div initial={{ opacity: 1 }} whileInView={{ opacity: 1 }}>
                        <StickyText onClick={() => navigate("/")}>
                            <HorizontalText title="Back to Homepage"></HorizontalText>
                        </StickyText>
                    </motion.div>
                    <AboutFilm>
                        <Container>
                            <AnimatePresence key={filmID} mode="wait">
                                <Title>About this project</Title>
                                {filmContent.map((aboutText, index) => {
                                    return (
                                        <motion.p
                                            className="description"
                                            key={index}
                                            variants={animations}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{ duration: 1 }}
                                        >
                                            {aboutText}
                                        </motion.p>
                                    );
                                })}
                            </AnimatePresence>
                        </Container>
                    </AboutFilm>
                    <FilmSliderSection>
                        <Container>
                            <AnimatePresence key={filmID} mode="wait">
                                <FilmSliderBox>
                                    {filmData.contentType !== "website" ? (
                                        <CastInfo>
                                            <Title>Cast</Title>
                                            <ul>
                                                {filmCast.map((textContent, index) => {
                                                    return (
                                                        <motion.li
                                                            key={index}
                                                            variants={animations}
                                                            initial="initial"
                                                            animate="animate"
                                                            exit="exit"
                                                            transition={{ duration: 1 }}
                                                        >
                                                            {textContent}
                                                        </motion.li>
                                                    );
                                                })}
                                            </ul>
                                        </CastInfo>
                                    ) : (
                                        ""
                                    )}
                                </FilmSliderBox>
                            </AnimatePresence>
                        </Container>
                    </FilmSliderSection>
                    <OtherProjects>
                        <Container>
                            <Title>Other Projects</Title>
                        </Container>
                        <div className="sliderContainer" onMouseEnter={() => enterMouseHandler()} onMouseLeave={() => outMouseHandler()}>
                            <FilmSlider slideIndex={videoSlideIndex} />
                        </div>
                    </OtherProjects>
                    <Footer variant="transparent" bgimg={+false} />
                </FilmContentView>
            </FilmDirectionContainer>
        </FilmDirectionSection>
    );
};

export default FilmDirection;
