// import { Col, Container, Image, Row } from "react-bootstrap";
// import { Images, SVGImages } from "../../config/images";
import { ProjectPageContainer, StickyText } from "./styles";
import { useEffect } from "react";
import { tabs } from "../../utils/projectTabs";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";
import HorizontalText from "../../components/HorizontalText";
import { useNavigate } from "react-router-dom";
import ProjectTabComp from "../../components/ProjectTabComp";
import LinerBackground from "../../components/LinerBackground";
import ConditionalContent from "../../components/ConditionalContent";
import Header from "../../components/Header";

const Projects = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <ProjectPageContainer>
            <ConditionalContent $minWidth={992}>
                <Header />
            </ConditionalContent>
            <ProjectTabComp tabs={tabs} />
            <motion.div initial={{ opacity: 1 }} whileInView={{ opacity: 1 }} onClick={() => navigate("/")}>
                <StickyText>
                    <HorizontalText title="Back to Homepage"></HorizontalText>
                </StickyText>
            </motion.div>
            <Footer bgimg={false} />
            <LinerBackground style={{ position: "fixed" }} />
        </ProjectPageContainer>
    );
};

export default Projects;
