import { Images } from "../config/images";
import FanollyPageContent from "../pages/Projects/FanollyPageContent";
import SnaplarkPageContent from "../pages/Projects/SnaplarkPageContent";
import SumoFamePageContent from "../pages/Projects/SumoFamePageContent";
import CheesiePageContent from "../pages/Projects/CheesiePageContent";
import WashiPageContent from "../pages/Projects/WashiPageContent";

export const tabs = [
    { title: "Snaplark", content: SnaplarkPageContent, id: "snaplark", color: "#3093ff", bgcolor: "#3093ff2b" },
    { title: "Fanolly", content: FanollyPageContent, id: "fanolly", color: "#f8da76", bgcolor: "#f8da762b" },
    { title: "Sumofame", content: SumoFamePageContent, id: "sumofame", color: "#ed181f", bgcolor: "#ed181f2b" },
    { title: "Cheesie", content: CheesiePageContent, id: "cheesie", color: "#ae3a3b", bgcolor: "#ae3a3b2b" },
    { title: "Washi", content: WashiPageContent, id: "washi", color: "#2a8264", bgcolor: "#2a82642b" }
];

export const projectData = [
    {
        id: 1,
        title: "Snaplark",
        content:
            "Revolutionizing the screen capture process, this cutting-edge screenshot video and GIF recording software dramatically reduces meeting durations with its innovative features.",
        color: "#3093ff",
        isActive: true,
        projectBgImage: "",
        link: "#snaplark"
    },
    {
        id: 2,
        title: "Fanolly",
        content:
            "Presenting a rapidly expanding media network tailored to cinema enthusiasts, thoughtfully divided into three vibrant networks: Bollyticket, Kollyticket, and Fanolly Pictures.",
        color: "#f8da76",
        isActive: false,
        projectBgImage: "",
        link: "#fanolly"
    },
    {
        id: 3,
        title: "Sumofame",
        content:
            "Sumofame stands as a dynamic media promotion company, focusing on the effective promotion of diverse content forms across various platforms.",
        color: "#ed181f",
        isActive: false,
        projectBgImage: "",
        link: "#sumofame"
    },
    {
        id: 4,
        title: "Cheesie",
        content:
            "Located in Hyderabad, India, Cheesie is a pioneering cheese production company dedicated to serving the discerning tastes of the South Indian market with its innovative range of products.",
        color: "#ae3a3b",
        isActive: false,
        projectBgImage: Images.cheeisebgimg,
        link: "#cheesie"
    },
    {
        id: 5,
        title: "Washi",
        content:
            "Introducing Washi, a unique solution designed to thoroughly cleanse fruits and vegetables by effectively removing harmful pesticides, ensuring a safer and healthier food experience.",
        color: "#2a8264",
        isActive: false,
        projectBgImage: Images.washibgimg,
        link: "#washi"
    }
];
export const projectSlideData = [
    {
        id: 1,
        title: "Snaplark",
        projectImage: Images.snaplarkSlideImg,
        link: "#snaplark"
    },
    {
        id: 2,
        title: "Fanlloy",
        projectImage: Images.fanollySlideImg,
        link: "#fanlloy"
    },
    {
        id: 3,
        title: "Sumofame",
        projectImage: Images.sumofameSlideImg,
        link: "#sumofame"
    },
    {
        id: 4,
        title: "Cheesie",
        projectImage: Images.cheesieSlideImg,
        link: "#cheesie"
    },
    {
        id: 5,
        title: "Washi",
        projectImage: Images.washiSlideImg,
        link: "#washi"
    }
];
