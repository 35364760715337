import styled from "styled-components";

export const VimeoWrapper = styled.div`
    position: relative;
    /* height: 100vh; */
    z-index: 3;
    margin-top: -25px;

    @media only screen and (max-width: 1280px) {
        margin-top: 0px;
    }
    /* &.vimeo-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        pointer-events: none;
        overflow: hidden;
    }
    &.vimeo-wrapper iframe {
        width: 100vw !important;
        height: 56.25vw !important; // Given a 16:9 aspect ratio, 9/16*100 = 56.25
        min-height: 100vh;
        min-width: 177.77vh; // Given a 16:9 aspect ratio, 16/9*100 = 177.77
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } */
`;
