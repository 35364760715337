import styled from "styled-components";

export const FilmContainer = styled.div`
    background-color: #ffffff;
    padding: 90px 0 0px;
    position: relative;
    overflow: hidden;
    transition: 0.2 ease;

    @media only screen and (max-width: 767px) {
        padding: 3em 15px;
    }
`;

export const HeadingContent = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 122px;

    @media only screen and (max-width: 767px) {
        padding-bottom: 1em;
        /* text-align: center;
        justify-content: center; */
    }

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 66px;
        line-height: 80px;
        color: #303030;
        transition: all 0.2s ease-in-out;

        @media only screen and (max-width: 767px) {
            font-size: clamp(34px, 4vw, 66px);
            line-height: 1.2;
            margin-bottom: 0;
            font-weight: 600;

            content: "hekkow";
            /* br {
                display: none;
            } */
        }
    }

    button {
        @media only screen and (max-width: 576px) {
            width: auto;
            max-width: unset;
            min-width: unset;
            height: auto;
            span {
                font-size: 16px;
                line-height: 1.2;
            }
        }
    }
`;

export const FilmSliderSection = styled.div`
    position: relative;
    padding-left: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 00px;

    @media only screen and (max-width: 992px) {
        padding: 0 15px;
    }
    @media only screen and (max-width: 767px) {
        padding-left: 0px;
    }
`;
export const FilmSliderBox = styled.div`
    width: calc(100% - 0px);
`;

export const FilmContainerMain = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;

    .videoSliderOrg {
        position: relative;
        z-index: 1000;
    }

    .original {
        position: relative;
        z-index: 20;
        height: 100%;
    }
    .maskOverlay {
        background-color: #15161a;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 22;
        -webkit-transition: 0.1s ease;
        -o-transition: 0.1s ease;
        transition: 0.1s ease;

        @media only screen and (max-width: 1024px) {
            display: none;
        }

        h2,
        .ScrollInfo_Contents {
            filter: brightness(0) invert(1);
        }

        .btn {
            font-size: 20px;
        }

        .slick-slider {
            @media only screen and (min-width: 768px) {
                &::after {
                    content: "";
                    position: absolute;
                    width: 200px;
                    height: 100%;
                    background-image: linear-gradient(to left, #15161a, #15161a00);
                    top: 0;
                    right: 0;
                }
            }
        }
    }
`;
