import styled from "styled-components";
import { Images } from "../../../../../config/images";

const ShapesContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    .imageShape {
        position: absolute;
        width: 40vw;

        animation: shapeRotate 15s linear infinite;

        &.imageShape1 {
            width: 60vw;
            top: 0;
            left: 0;
        }
        &.imageShape2 {
            // height: 150%;
            bottom: -18vw;
            right: -12%;
        }
    }

    @keyframes shapeRotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(1turn);
        }
    }
`;

const FanollyContent = () => {
    return (
        <ShapesContainer className="shapes">
            <img src={Images.shapeL1Img} alt="L" className="imageShape imageShape1" />
            <img src={Images.shapeL2Img} alt="L" className="imageShape imageShape2" />
        </ShapesContainer>
    );
};

export default FanollyContent;
