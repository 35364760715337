import styled from "styled-components";
import { motion } from "framer-motion";
import { Images } from "../../config/images";

export const PhotoGraphyContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    position: relative;
`;

export const Sidebar = styled.div`
    width: 337px;
    height: 100vh;
    padding: 20px 20px;
    background-image: url(${Images.sidebarBackground});
    background-position: top center;
    background-size: cover;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;

    > svg {
        width: 146px;
        height: 64px;
    }

    ul {
        margin: 0px 0 0;
        padding: 0;
        height: calc(100vh - 237px);
        overflow-y: auto;

        li {
            list-style: none;
            font-size: 18px;
            font-family: "Helvetica";
            margin-bottom: 10px;
            font-weight: 300;
            line-height: 1.5;
            cursor: pointer;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                position: relative;

                &::after {
                    content: "";
                    width: 0%;
                    height: 1px;
                    background-color: #fff;
                    transition: width 0.3s ease-in-out;

                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            svg {
                opacity: 0;
                margin: 0 10px;
            }

            @media only screen and (min-width: 1600px) {
                font-size: 20px;
                margin-bottom: 20px;
            }

            &.selected {
                span {
                    &::after {
                        width: 100%;
                    }
                }

                svg {
                    opacity: 1;
                }
            }

            &:hover {
                span {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        gap: 20px;

        /* svg {
            width: 33px;
            height: 33px;

            path {
                stroke: #fff;
            }
        } */

        a {
            font-size: 22px;
            color: #fff;
            font-family: "Helvetica";
            text-decoration: none;
            font-weight: 300;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
        width: 100%;

        .siteLogo {
            width: 82px;
            height: auto;
            cursor: pointer;
        }

        .closeIcon {
            align-self: flex-start;
        }
    }

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: ${(props) => (props.$isOpen ? "0" : "-100%")};
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        z-index: 10000;
        transition: all 0.3s ease-in-out;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const FloatingButton = styled.div`
    position: fixed;
    top: 20px;
    left: 15px;
    z-index: 1000 !important;
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-color: transparent !important;
    height: 45px;
    width: 45px;
    padding: 8px !important;

    background-color: transparent !important;
    span {
        width: 20px;
        height: 2px;
        background-color: #ffffff;
        transition: all 0.3s ease-in-out;
        border-radius: 10px;

        &.centerLine {
            width: 18px;
        }
        &:last-child {
            width: 15px;
        }

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &:hover {
        background-color: transparent !important;

        span {
            width: 20px;

            &.centerLine {
                width: 20px;
            }
            &:last-child {
                width: 20px;
            }
        }
    }

    @media only screen and (min-width: 1600px) {
        height: 60px;
        width: 60px;
        span {
            width: 30px;
            height: 2px;

            &.centerLine {
                width: 25px;
            }
            &:last-child {
                width: 20px;
            }

            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        &:hover {
            span {
                width: 30px;

                &.centerLine {
                    width: 30px;
                }
                &:last-child {
                    width: 30px;
                }
            }
        }
    }
`;

export const MasonaryContainer = styled.div`
    width: 100%;
    padding: 60px;
    padding-left: calc(337px + 62px);
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    height: 100vh;
    overflow: auto;
    @media (max-width: 768px) {
        width: 100%;
        padding: 20px;
        padding-top: 100px;

        &::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            height: 150px;
            width: 100%;
            background-image: linear-gradient(to bottom, #15161a, transparent);
            z-index: 2;
        }
    }

    &.isActive {
        transition: opacity 0.5s ease-in-out;
        z-index: 99;
        opacity: 1;
    }
`;
export const SingleMasonaryContainer = styled.div`
    width: 100%;
    padding: 60px;
    padding-left: calc(337px + 62px);
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    height: 100vh;
    overflow: auto;

    &.isActive {
        z-index: 99;
        opacity: 1;
        transition: all 0.5s ease-in-out;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 20px;
        padding-top: 100px;

        &::before {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            height: 150px;
            width: 100%;
            background-image: linear-gradient(to bottom, #15161a, transparent);
            z-index: 2;
        }
    }
`;

export const CategoryCard = styled.div`
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &::after {
        content: "";
        display: block;
        height: 200%;
        position: absolute;
        left: 0;
        bottom: 0%;
        width: 100%;
        opacity: 1;
        transform: translateY(50%);
        background: linear-gradient(00deg, rgba(0, 0, 0, 1) 54%, rgba(0, 0, 0, 0.7) 75%, rgba(0, 0, 0, 0) 100%);
        transition: all 0.3s ease-in-out;
    }

    img {
        width: 100%;
        border-radius: 16px;
    }

    p {
        position: absolute;
        bottom: 0;
        font-size: 18px;
        width: 100%;
        text-align: center;
        font-family: "Helvetica";
        font-weight: 300;
        z-index: 1;
        margin-bottom: 0px;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        &::after {
            bottom: 0px;
            transform: translateY(0%);
            transition: all 0.3s ease-in-out;
            opacity: 0.8;
        }

        p {
            transition: all 0.3s ease-in-out;
            height: 100%;
        }
    }
`;

export const ImageCard = styled(motion.div)`
    cursor: pointer;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        border-radius: 16px;
    }
`;

export const LoadingOverlayBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    /* Loading overlay covers the player */
    width: 100%;
    height: 100%;
    /* Loading overlay has semi-transparent background so the player
          will be darkened while it's visible */
    background-color: rgba(0, 0, 0, 0.7);
    /* Center the spinner inside of the overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .overlay {
        width: 4em;
        height: 4em;
        border: 6px solid white;
        border-radius: 50%;
        border-color: white white transparent transparent;
        animation: loadingOverlaySpinnerAnimation 1s linear infinite;
    }

    @keyframes loadingOverlaySpinnerAnimation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
