import styled from "styled-components";
import { Offcanvas, Button } from "react-bootstrap";
import GradientButton from "../GradientButton";
import FONTS from "../../config/fonts";

export const HeaderSection = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;

    &.sticky {
        transform: translateY(-120px);
    }

    .rightMenuBar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;

        p {
            margin-bottom: 0;
            font-size: 25.39px;
            line-height: 1;

            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        @media only screen and (max-width: 767px) {
            a svg {
                width: 25px;
                height: 25px;
            }
        }
    }
`;
export const RightSlideBar = styled(Offcanvas)`
    background: rgba(21, 21, 24, 0.6);
    backdrop-filter: blur(100px);
    max-width: 520px;
    width: 100% !important;
    padding: 2em 3em;
    height: 100vh !important;

    @media only screen and (max-width: 767px) {
        padding: 15px;
    }

    .btn-close {
        /* filter: brightness(1) invert(1); */
        background: none !important;
        width: 42px;
        height: 42px;
        padding: 0;
        margin-right: 0px;

        svg {
            width: 100%;
            height: 100%;
        }

        &:focus,
        &:active,
        &:focus-within {
            outline: unset !important;
            box-shadow: unset !important;
        }

        @media only screen and (max-width: 767px) {
            width: 32px;
            height: 32px;
        }
    }

    .offcanvas-header {
        padding: 0;
    }

    .offcanvas-body {
        padding: 3em 0em 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: calc(100vh - 90px);

        @media only screen and (max-width: 767px) {
            padding: 15px 15px 0;
        }
    }
`;

export const ResumeButton = styled(GradientButton)`
    span {
        font-family: ${FONTS.helvetica};
        font-size: 16px;
        font-weight: 400;
        justify-content: center !important;
        padding: 5px !important;
    }

    @media only screen and (max-width: 767px) {
        transform: scale(0.5);
        transform-origin: left center;
    }
`;
export const ContentBox = styled.div`
    margin-bottom: 15px;
    h3 {
        font-family: "Helvetica";
        font-size: 26px;
        line-height: 1.2;
        margin-bottom: 15px;

        @media only screen and (max-width: 767px) {
            font-size: 22px;
        }
    }

    p,
    a {
        font-family: "Helvetica";
        font-size: 24px;
        line-height: 1.2;
        font-weight: 300;
        margin-bottom: 15px;
        color: #ffffff !important;

        @media only screen and (max-width: 767px) {
            font-size: 20px;
        }
    }

    a {
        text-decoration: none;
        display: block;
        opacity: 0.7;

        &:hover {
            text-decoration: none;
            opacity: 1;
        }
    }
`;

export const MenuButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    border-color: transparent !important;
    height: 45px;
    width: 45px;
    padding: 8px !important;
    margin-left: 10px;
    background-color: transparent !important;

    @media only screen and (min-width: 768px) {
        margin-left: 30px;
    }
    span {
        width: 20px;
        height: 2px;
        background-color: #ffffff;
        transition: all 0.3s ease-in-out;
        border-radius: 10px;

        &.centerLine {
            width: 18px;
        }
        &:last-child {
            width: 15px;
        }

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &:hover {
        background-color: transparent !important;

        span {
            width: 20px;

            &.centerLine {
                width: 20px;
            }
            &:last-child {
                width: 20px;
            }
        }
    }

    @media only screen and (min-width: 1600px) {
        height: 60px;
        width: 60px;
        span {
            width: 30px;
            height: 2px;

            &.centerLine {
                width: 25px;
            }
            &:last-child {
                width: 20px;
            }

            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }

        &:hover {
            span {
                width: 30px;

                &.centerLine {
                    width: 30px;
                }
                &:last-child {
                    width: 30px;
                }
            }
        }
    }
`;
